@import "./../../../../styles/abstracts/variables";

.quizMTFComponent {
    height: 100%;
    width: 65%;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (max-width: $md) {
        width: 80%;
        height: 100%;
        padding-top: 12rem;
        // padding: unset;
        // justify-content: space-between;
        padding-bottom: 2rem;
    }

    @media only screen and (min-width: $md) {
        padding-top: clamp(4rem, 11vh, 6.2rem); //clamp(2.5rem, 2vh, 5rem);
    }
    margin: 0 auto;

    &__question {
        @media screen and (max-width: $md) {
            max-height: 12rem !important;
        }
    }

    &__container {
        display: flex;
        align-items: center;
        justify-content: center;

        @media screen and (max-width: $md) {
            padding-top: 1rem;
        }

        &__match{
            width: 100%;
            display: flex;
            flex-direction: column;
            padding-bottom: 1rem;
            @media only screen and (min-width: $md) {
                min-width: 50rem;
            }
        }

        @media screen and (min-width: $md) {
            gap: 10rem;
        }
        @media screen and (max-width: $md) {
            flex-direction: column; 
            justify-content: center;
            align-items: center;
        
            &__answerCount {
              order: 1;
            }
        
            &__skipBtn {
              order: 2;
              padding-top: 1rem;
              padding-bottom: 1rem;
            }
        
            &__match {
              order: 3;
            }
        }
    }
}

.quizMTFComponent__wrapper {
    display: flex;
    padding-bottom: 1rem;
}

.quizMTFComponent__submitButton {
    @media only screen and (max-width: $md) {
        padding-bottom: 1rem;
    }
}

.quizMTF__matchAnswerWrapper{
    width: 100%;
    min-height: 100%;
    border: 2px dashed white;
    border-radius: 0.5rem;

}

.quizMTF__arrow{
    margin: 0 2rem;
    
    @media screen and (max-width: $md) {
        margin: 0 1rem;
    }
    @media screen and (max-width: $sm) {
        margin: 0 0.5rem;
    }
}

.quizMTFComponent__line{
    margin: 2rem 0;
    border: 2px solid white;
    background: #fff;
    @media screen and (max-width: $md) {
        border: 1px solid white;
        margin: 1rem 0;
    }
    @media screen and (max-width: $sm) {
        border: 0.5px solid white;
        margin: 0.5rem 0;
    }
}

.quizMTFComponent__optionsDroppable{
    display: flex;
    // background-color: green;
    // justify-content: space-between;
    flex-wrap: wrap;
    min-height: 1rem;
}

.quizMTFComponent__dropBox {
    min-height: 1rem;
    min-width: 10rem;
    border: 2px dashed white;
}

.quizMTF__matchQuestion {
    width: 50%;
    text-align: left;
    // width: 100%;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 6px;
    background-color: transparent;
    color: #fff;
    border: 2px solid #fff;
    border-radius: 10px;
    font-family: "CaustenM";
    font-size: 28px;

    letter-spacing: 0.1px;

    // margin-top: 10px;

    // &:hover {
    //     background-color: #fff;
    //     color: #30b2e7;
    // }

    @media only screen and (min-width: $md) {
        font-size: clamp(1rem, 3vmin, 3rem);
    }

    @media only screen and (max-width: $md) {
        font-size: 15px;
        letter-spacing: 0.05px;
        padding: unset;
        padding-left: 0.5rem;
        padding-top: 0.5rem;
    }
    @media only screen and (max-width: $md) and (min-height: $md) {
        font-size: 30px;
        letter-spacing: 0.05px;
    }
    @media only screen and (min-width: $md) and (max-height: $md) {
        padding-top: 6px;
        padding-bottom: 6px;
    }
    @media only screen and (min-width: $md) and (max-height: $sm) {
        padding-top: 0;
        padding-bottom: 0;
    }
}


.quizMTF__matchOption {
    text-align: center;
    // width: 100%;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 6px;
    padding-right: 6px;
    background-color: white;
    color: #30b2e7;
    border: 2px solid #fff;
    border-radius: 10px;
    font-family: "CaustenM";
    font-size: 28px;

    letter-spacing: 0.1px;

    margin-top: 10px;
    margin-right: 1rem;

    &:hover {
        background-color: #fff;
        color: #30b2e7;
    }

    @media only screen and (min-width: $md) {
        font-size: clamp(1rem, 3vmin, 3rem);
    }

    @media only screen and (max-width: $md) {
        font-size: 15px;
        letter-spacing: 0.05px;
        padding: 0.5rem;
        // padding-left: 0.5rem;
    }
    @media only screen and (max-width: $md) and (min-height: $md) {
        font-size: 30px;
        letter-spacing: 0.05px;
    }
    @media only screen and (min-width: $md) and (max-height: $md) {
        padding-top: 6px;
        padding-bottom: 6px;
    }
    @media only screen and (min-width: $md) and (max-height: $sm) {
        padding-top: 0;
        padding-bottom: 0;
    }
}

.quizMTF__matchAnswer {
    // min-width: 50%;
    text-align: left;
    // width: 100%;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 6px;
    padding-right: 6px;
    background-color: white;
    color: #30b2e7;
    border: 2px solid #fff;
    border-radius: 10px;
    font-family: "CaustenM";
    font-size: 28px;

    letter-spacing: 0.1px;

    // margin-top: 10px;
    width: fit-content;
    width: 100%;

    &:hover {
        background-color: #fff;
        color: #30b2e7;
    }

    @media only screen and (min-width: $md) {
        font-size: clamp(1rem, 3vmin, 3rem);
    }

    @media only screen and (max-width: $md) {
        font-size: 15px;
        letter-spacing: 0.05px;
        padding: 0.5rem;
        
        
    }
    @media only screen and (max-width: $md) and (min-height: $md) {
        font-size: 30px;
        letter-spacing: 0.05px;
    }
    @media only screen and (min-width: $md) and (max-height: $md) {
        padding-top: 6px;
        padding-bottom: 6px;
    }
    @media only screen and (min-width: $md) and (max-height: $sm) {
        padding-top: 0;
        padding-bottom: 0;
    }
}

.quizMTF__leaderboardOpen {
    width: 80%;
}