@import "../../../styles/abstracts/_variables.scss";

.slide{
    width: 100%;
    height: 100%;
}

.selectSlide{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
    gap: 1.5rem; 
    & > p {
        font-size: 1.5625rem;
    }
}

.proceedbtn{
    display: flex;
    justify-content: center;
    margin-top: 4rem;
}

.addQuestionSlide{
    display: flex;
    margin-top: 3rem;
    gap: 2rem;
    padding: 1rem;
}

.selectQuestionTypeSlide{
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 2rem;
    margin-top: 2rem;
}