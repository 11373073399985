@import "../../styles/abstracts/variables";

.userauth{
    display: flex;
    background-color: $qq-dark-green;
    overflow-x: hidden;    
    height: 100vh; 
    color: white;
    //scroll bar styling for mozilla
    scrollbar-color: $qq-green $qq-dark-green;
    scrollbar-width: thin;

    &__image{
        display: flex;
        @media screen and (max-width:$md) {
            display: none;            
        }
        width: 48%;
        margin:auto;
        padding-top: 7%;       

        & > img{
            margin-top: auto;
            position: relative;
            margin-left: -29%;
            height: 100%;
            width: 111%;
            position:relative;
        }
    }

    &__details{
        margin-top: min(5%, 3rem);
        display: flex;
        flex-direction: column;
        width: 60%;
        @media screen and (max-width:$md) {
            width: 99%;            
        }
        justify-content: center;
        align-items: center;
    }
}
.userauth::-webkit-scrollbar {
    width: 7px;
}
.userauth::-webkit-scrollbar-track {
    background-color: $qq-dark-green;
}
.userauth::-webkit-scrollbar-thumb {
    background: $qq-green; 
    border-radius: 7px;
}