@import "../../styles/abstracts/variables";

.welcomeBanner {
    display: flex;
    flex-direction: column;
    background-color: $qq-yellow;
    padding: 2.875rem  4.3125rem 2.5rem 4.3125rem;
    gap: 1.25rem;
    border-radius: 0.9375rem;
    width: 100%;

    &__salutation{
        font-family: 'CaustenR';
        font-style: normal;
        font-weight: 800;
        font-size: 3.125rem;
        line-height: 3.125rem;
    }
    &__description{
        font-family: 'CaustenR';
        font-style: normal;
        font-weight: 700;
        font-size: 1.5625rem;
        line-height: 1.875rem;
        white-space: pre-line;
    }
}