@import "./../../../styles/abstracts/variables";

.quizfooter {
  position: fixed;
  bottom: 0;
  display: flex;
  flex-direction: column;
  background-color: $qq-blue;
 

  &__iconContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // margin: 25px;
    margin: clamp(0.5rem, 2vh, 1.5rem);
    & > p {
      color: white;
      font-size: clamp(1rem, 4vmin, 3rem);
      font-family: "CaustenSB";
    }
  }

  &__left {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__trophy {
    border: 1px solid white;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 5px;
    height: 43px; //as per Pulak's doc
    width: 43px;
    
    @media screen and (max-height: $md) {
      height: 36px; //as per Pulak's doc
      width: 36px;
    }
    
  }

  .quizfooter__trophyleft {
    margin-right: 1rem;
  }

  &__modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    & > h1 {
      font-size: 4.3rem;
      font-family: "CaustenEB";
      text-align: center;

      @media only screen and (max-width: 1200px) {
        font-size: 3rem;
      }
      @media only screen and (max-width: 500px) {
        font-size: 2rem;
      }
    }

    & > p {
      font-family: "CaustenM";
      text-align: center;
      font-size: 1.25rem;
      font-weight: 300;
      max-width: 30rem;
    }
  }

  &__exitQuiz {
    height: 3rem;
    width: 12rem;
    background-color: #30b2e7;
    box-shadow: none !important;
    border-radius: 10px !important;
    font-family: "CaustenB" !important;
    font-size: 1.2rem !important;
    letter-spacing: 1px !important;
    margin: auto;

    @media only screen and (max-width: 500px) {
      width: 10rem;
      font-size: 1rem !important;
    }
  }
}