@import "../../styles/abstracts/variables.scss";

.navMenu {
    position: fixed;
    bottom: 0;
    display: none;
    width: 100%;
    z-index: 1300;
    background-color: $qq-yellow;
    @media only screen and (max-width: $md) {
       display: flex;
       height: 10%;
    }
    &__buttonContainer {
        padding: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: white;
        gap: 0.5rem;
        flex: 0.5;
        & > p {
            font-size: 0.8rem;
        }
        @media only screen and (max-width: 320px) {
            padding: 0.5rem;
         }
    }
}