@import "../../../../styles/abstracts/_variables.scss";

.miniSlide{
    display: flex;
    flex-direction: column;
    box-sizing: content-box;
    background: #FFFFFF;
    border-radius: 10px;
    width: 240px;
    max-height: 175px;
    min-height: 150px;
    padding: 0.5rem 1rem 0.5rem 1rem;
    margin: 0.5rem 0;
    flex-grow: 1;

    &__container{
        display: flex;
        flex-direction: row;
        gap: 1rem;
        flex-grow: 1;
        justify-content: space-between;
        align-items: center;
    }

    &__dragging{
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        justify-content: center;
        align-items: center;
    }    
    
    &__content{
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        justify-content: space-between; 
        align-self: flex-start;
        height: 100%;
        width: 100%;
    }


    &__active{        
        border: 7px solid $qq-yellow;
    }

    &__image{
        flex-basis: 0;
        flex-grow: 1;
        margin-top: 0.25rem;
        margin-bottom: 0.5rem;
        text-align: center;
        position: relative;
        justify-content: center;
        align-items: center;
        & > img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            height: 100%;
        }
        & > video {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            height: 100%;
        }
        & > audio {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
        }
    }

    &__header{
        display: flex;
        flex-direction: row;
        width: 100%;
        align-items: flex-start;
        justify-content: space-between;
    }

    &__title{
        text-align: left;
        font-family: 'CaustenR';
        font-style: normal;
        font-weight: 700;
        font-size: 0.875rem;
        line-height: 1.25rem;
        color: $grey-3;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    &__header__icon__container{
        width: 5%;
    }

    &__header__icon{
        color: $grey-3;
        transform: scale(1.3,1.3);
        &:hover{
            color: #000000;
        }
    }

}



.miniOptions{
    background: #E6F5FC;
    border-radius: 0.1875rem;
    height: 1rem;
    display: flex;
    padding-right: 0.25rem;
    justify-content: flex-end;
    align-items: center;

    &__correctOption{
        height: 0.5rem;
        width: 0.5rem;
        background-color: $qq-green;
        border-radius: 50%;
        display: inline-block;
    }
}