.questionDetails{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 2rem;
    & > div:first-child {
        margin-top: 2rem;
    }
    &__item{
        display: flex;
        justify-content: space-between;
        width: 80%;
        & > h2{
            font-family: 'CaustenR';
            font-style: normal;
            font-weight: 700;
            font-size: 1.25rem;
            line-height: 1.75rem;
        }
    }
}

.quantityIncrementButtons{
    display: flex;
    gap: 1rem;
    min-width: 6.875rem;
    align-items: center;
    justify-content: space-between;
    font-family: 'Causten Round';
    font-style: normal;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 1.75rem;
    & > svg{
        cursor: pointer;
    }
}