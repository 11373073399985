@import "../../styles/abstracts/variables.scss";

.inviteModal {
  &__iconButton {
    border: 1px solid #fff;
    color: white;
    border-radius: 10px;
  }
  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    @media screen and (max-width: $sm) {
      width: 100%;
      gap: 1.5rem;
    }
    & > h1 {
      font-size: 4.375rem;
      font-family: "CaustenEB";
      @media screen and (max-width: $sm) {
        font-size: 2rem;
      }
    }
  }
  &__link {
    display: flex;
    gap: 1.5rem;
    justify-content: center;
    background-color: #db9b00;
    align-items: center;
    padding: 0.3rem 1rem;
    border-radius: 8px;
    font-size: 1.75rem;
    font-family: "CaustenM";
    @media screen and (max-width: $sm) {
      width: 100%;
    }
    & > p:first-child {
      color: white;
      @media only screen and (max-width: $sm) {
        width: 6rem;
        overflow-x: auto;
        flex-grow: 1;
        &::-webkit-scrollbar {
          height: 0px;
        }
      }
      
    }
    @media only screen and (max-width: $sm) {
      font-size: 1rem;
      gap: 0;
    }
  }
  &__shareContainer {
    display: flex;
    gap: 2rem;
    @media screen and (max-width: $sm) {
      width: 100%;
      justify-content: space-between;
    }
  }
  &__copyButton {
    color: #fff;
    font-size: 1.4375rem;
    letter-spacing: 0.68px;
    @media only screen and (max-width: $sm) {
      font-size: 0.875rem;
      font-family: "CaustenEB";
    }
  }
  &__shareIconContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    & > p {
      font-family: "CaustenM";
      font-size: 1.5rem;
      line-height: 2.125rem;
      letter-spacing: 0.0056rem;
      @media only screen and (max-width: $sm) {
        font-size: 0.75rem;
        line-height: 0;
      }
    }
  }
}
