@import "./styles/abstracts/variables";

* {
  margin: 0;
  box-sizing: border-box;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
    Helvetica Neue, sans-serif;
  @media screen and (max-width: $md) {
    overflow: hidden;
  }
}

::-moz-selection {
  /* Code for Firefox */
  background: $qq-yellow;
}

::selection {
  background: $qq-yellow;
}

@font-face {
  font-family: "CaustenB";
  src: url("/fonts/Causten/Trustha - Causten Round Bold.otf");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "CaustenEB";
  src: url("/fonts/Causten/Trustha - Causten Round Extra Bold.otf");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "CaustenM";
  src: url("/fonts/Causten/Trustha - Causten Round Medium.otf");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "CaustenL";
  src: url("/fonts/Causten/Trustha - Causten Round Light.otf");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "CaustenR";
  src: url("/fonts/Causten/Trustha - Causten Round Regular.otf");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "CaustenSB";
  src: url("/fonts/Causten/Trustha - Causten Round Semi Bold.otf");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

h1 {
  font-family: "CaustenB";
}

p {
  font-family: "CaustenR";
  letter-spacing: 0.8px;
}

option {
  color: black;
}

/* Scrollbar style*/

/* width */
.main::-webkit-scrollbar {
  width: 7px;
}

/* Track */
.main::-webkit-scrollbar-track {
  background-color: #30b2e7;
}

.main-green::-webkit-scrollbar-track {
  background-color: #75b343;
}

/* Handle */
.main::-webkit-scrollbar-thumb {
  background: #007FB3;
  border-radius: 7px;
}

.main-green::-webkit-scrollbar-thumb {
  background: #4b9710;
  border-radius: 7px;
}

/* Handle on hover */
// .main::-webkit-scrollbar-thumb:hover {
//     background: #0785b8; 
//   }


.back__Arrow {
  position: absolute;
  left: 5%;
  top: 18%;
  z-index: 1200;
  cursor: pointer;

  @media only screen and (max-width: 600px) {
    height: 1.25rem;
    object-fit: contain;
    top: 6.3rem;
  }
}

.prevent-select { /* prevent selecting of text */
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.menuOption {
  cursor: pointer;
}

.menuOptions { //Override MUI menu with custom theme
  background-color: #FFFFFF !important;
  min-width: 130px !important;
  border-radius: 8px !important;

  .MuiList-root {
    padding: 0 !important;
  }

  .MuiMenuItem-root {  //Customise Menu items
    color: #000000 !important;
    font-family: "CaustenR" !important;
    font-weight: 400 !important;
    font-size: 1rem !important;
    &:focus {
      background-color: transparent !important;
    }
    &:hover {
      background-color: #d6e9c7 !important;
      font-weight: 600 !important;
    }
  }
}