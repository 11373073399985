@import "../../styles/abstracts/_variables.scss";

.embedComponent{
    display: flex;
    background-color: #30b2e7;
    overflow-x: hidden;
    height: 100vh; // changed by sani
    width: 100%;
    color: white;
    padding-left: 4.375rem;
    padding-right: 4.375rem;
    padding-top: 20vh;
    padding-bottom: 5vh;

    @media screen and (max-width: $md) {
        position: fixed;
        height: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    &__mode{
        width: 30%;
        // background-color: red;   
        & >h2 {
            margin-bottom: 2rem ;
        }     
        & >h4 {
            margin-bottom: 2rem;
        }

        &__types {
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;

            &__item{
                position: relative;
                width: 25%;
                padding-bottom: 25%;
                outline: 2px solid white;
                border-radius: 10px;
                cursor: pointer;

                &__inner{
                        position: absolute;
                        display: flex;
                        flex-direction: column;
                        font-size: clamp(0.75rem, 1.5vw,1rem);
                        left: 10px;
                        right: 10px;
                        top: 10px;
                        bottom: 10px;
                        align-items: center;
                        justify-content: center;
                        text-align: center;
                    }
            }
        }

        &__getcode{
            margin-top: 5rem;
            display: flex;
            align-items: center;
            justify-content: center;

        }
        

    }
    &__preview{
        width:70%;
        display: flex;
        align-items: center;
        justify-content: center;
        &__fullpage{
            width:100%;
            display: none;
            // display: flex; on click change to flex
            align-items: center;
            justify-content: center;
            position: relative;
            padding-bottom: 56.25%;
            height: 0;
            overflow: hidden;

            & > iframe {
                position: absolute;
                top:0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }  

        &__popup{
            position: fixed;
            display: none;
            top: 0;
            left: 0;
            background: rgba(0,0,0,0.9);
            height: 100%;
            width: 100%;
            z-index: 100;

            & > span{
                position: absolute;
                top: 0;
                right: 10px;
                font-size: 40px;
                font-weight: bolder;
                color: #fff;
                cursor: pointer;
                @media screen and (max-width:$md) {
                    right: 5px;
                }
            }

            & > embed {                
                position: absolute;
                top:50%;
                left: 50%;
                transform:translate(-50%, -50%) ;
                border: 5px solid #fff;
                border-radius: 10px;
                width: 80%;
                height: 90%;
                object-fit: cover;
                @media screen and (max-width:$md) {
                    width: 95%;
                    height: 88%;
                }
            }
        }  
    }
}

