@import "../../styles/abstracts/variables";

.displayCard {
  background-color: $qq-dark-green;
  height: 100%;
  color: white;
  font-family: "CaustenEB";
  display: flex;
  flex-direction: column;
  align-items: center;

  &__container {
    display: flex;
    background-color: $qq-yellow-3;
    padding: 1rem;
    gap: 1.5rem;
    border-radius: 1rem;
    @media screen and (max-width: $md) {
      flex-direction: column;
      margin: 1rem;
    }
  }

  &__info {
    background-color: $qq-green-3;
    border-radius: 1rem;
    font-size: xx-large;
    & > p {
      padding: 0.5rem 1rem 0.5rem 1rem;
    }
  }

  &__copyButton {
    color: #fff;
    font-size: 1.4375rem;
    letter-spacing: 0.68px;
    @media only screen and (max-width: $md) {
      font-size: 0.875rem;
      font-family: "CaustenEB";
    }
  }

  &__copyButtonContainer {
    @media only screen and (max-width: $md) {
      display: flex;
      justify-content: center;
    }
  }
}
