@import "../../styles/abstracts/_variables.scss";

.podium {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: #30b2e7;
  gap: 2rem;
  color: white;
  @media screen and (max-width: $md) {
    padding-top: 7rem;
    gap: 1rem;
    overflow-y: hidden;
    height: 100%;
  }
  
  
  
  &__button {
    background-color: $qq-yellow;
    color: white;
    height: 3rem;
    width: 16rem;
    font-size: 1.2rem;
    font-family: "CaustenM" !important;
    margin-bottom: 3rem;
    &:hover {
      background-color: $qq-yellow;
    }
  }
  
  & > h1 {
    text-align: center;
    font-family: "CaustenEB";
    font-size: 3.125rem;
    line-height: 3.4375rem;
    margin-top: 10rem;
    @media screen and (max-width: $md) {
      margin-top: 0rem;      
    } 
    @media screen and (max-width: $sm) {
      font-size: 2rem;
      line-height: 2.5rem;
    }
  }
  
  &__shareContainer {
    display: flex;
    justify-content: center;
    gap: 1rem;
    width: 100%;
    @media screen and (max-width: $sm) {
      gap:2rem;
    } 
  }

  &__actions {
    display: flex;
    gap: 4rem;
    cursor: pointer;
    margin-top: auto;
    @media screen and (max-width: $sm) {
      flex-direction: column;
      gap: 2rem;
    } 
  }

  &__iconText {
    display: flex;
    flex-direction: column;
    @media screen and (max-width: $sm) {
      flex-direction: row;
      align-items: center;
    } 

    gap: 1rem;
    justify-content: center;
    & > img {
      height: 2rem;
      object-fit: contain;
      @media screen and (max-width: $sm) {
        height: 1.5rem;
      } 
    }
    & > p {
      font-family: "CaustenR";
      font-size: 1.125rem;
      letter-spacing: 0.0462rem;
      line-height: 1.125rem;
      @media screen and (max-width: $sm) {
        font-size: 0.875rem;
        letter-spacing: 0.0313rem;
        line-height: 0.875rem;
        font-family: "CaustenEB";
      } 
    }
  }
  &__footer {
    // margin-top: auto;
    width: 100%;
    padding: 2rem;
    margin-left: 4rem;
    @media screen and (max-width: $sm) {
      margin-left: 0rem;
    }
    background-color: #30b2e7;
    & > p {
      font-family: "CaustenM";
      letter-spacing: 0.0206rem;
      line-height: 1.25rem;
      font-size: 1.25rem;
      margin-bottom: 1rem;
      @media screen and (max-width: $sm) {        
        font-size: 0.9375rem;
        letter-spacing: 0.0156rem;
        line-height: 0.9375rem;
      } 
    }
    @media screen and (max-width: $sm) {
      margin-top: 1rem;
      display: flex;
      flex-direction: column;      
      align-items: center;
    } 
  }
  &__footer-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__iconButton {
    border: 1px solid white;
    border-radius: 10px;
  }

  &__copyScoreBtn{
    margin-left: 1rem;
    color: #ffffff;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    font-size: x-large;

    @media screen and (max-width: $sm) {
      font-size: large;
    }
  }

  &__copyScoreContainer{
    // border: 1px solid #FFFFFF;
    border-radius: 9px;
    padding: 2px;
    position: relative;
    width: 48px;
    height: 48px;
    cursor: pointer;
    background-color: #0097D4;

    @media screen and (max-width: $sm) {
      width: 40px;
      height: 40px;
    }
  }
  
}

.podium__leaderboardContainer{
  width: 90%;
  height: 100%;
}

.podium__overflow {
  @media screen and (max-width: $md) {
    overflow-y: auto;
  }

}