@import "../../styles/abstracts/variables";
.multiSelect{
    &__label{
        font-family: "CaustenR";
        font-size:  clamp(1rem, 3vw ,1.375rem);               
        font-weight: 700;
        letter-spacing: 0.0313rem;
        line-height: 1.375rem;
        margin-bottom: 1rem;
    }
    & .MuiFormControl-root .MuiOutlinedInput-root {
        background-color: $qq-blue-3;
        & .MuiOutlinedInput-notchedOutline{
            border: none !important;
        }
        & svg {
            height: 24px;
            width: 24px;
            color: white;
            stroke-width: 0.25px;
        }
    }      
}


.MuiPaper-root::-webkit-scrollbar {
    width: 7px;
}

.MuiPaper-root::-webkit-scrollbar-track {
    background-color: $qq-blue;
}

.MuiPaper-root::-webkit-scrollbar-thumb {
    background: $qq-dark-blue; 
    border-radius: 7px;
}