@import "./../../../styles/abstracts/variables";

.quizMobileFixedHeader {
    width: 100%;
    position: fixed;
    // left: 50%;
    // top: clamp(10vh, 1vh, 13vh);
    // transform: translateX(-50%);
    top: 1px;
    padding-top: 90px;
    display: flex;
    justify-content: center;
    // height: 100%;
    @media screen and (min-width: $md) {
        // top: 12vh;
        padding-top: 100px;
    }
    
    background-color: $qq-blue;
    &__container {
        height: 100%;
        @media screen and (max-width: $md) {
            width: 90%;
            background-color: $qq-blue;
            color: white;
            
            // transform: translateX(-50%);
            @media screen and (max-height: 800px) {
                // top: 12vh;
                // top: 10vh;
            }

        }
    }
}
.quizMobileFixedHeader__fullheight {
    height: 100%;
}
