@import "../../../styles/abstracts/_variables.scss";
.ShareQuiz{
    display: flex;
    flex-direction: column;
    justify-content: center;
    &__proceedbtn{
        display: flex;
        justify-content: center;
        padding-top: 1rem;
    }
    &__subtitle{
        font-family: 'CaustenR';
        font-style: normal;
        font-weight: 400;
        line-height: 1.25rem;
        text-align: center;
        margin-top: 0.75rem;
        margin-bottom: 2.625rem;
    }
    &__container{
        display: flex;
        gap: 3.5rem;
        &__left, &__right{
            display: flex;
            flex-direction: column;
            flex-grow: 1;
        }

        &__publicOnly{
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            align-items: center;
        }

        &__right{
            max-height: 22rem;
            overflow-y: auto;
            padding-right: 0.5rem;
        }

        &__left {
            &__input{
                & > input {
                    background-color:$qq-blue-3 !important;
                    border: unset !important;
                    padding-left: 1.25rem !important;
                }  
                ::placeholder {
                    color: white;
                    font-family: 'CaustenR';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 1rem;
                    line-height: 1rem;
                    opacity: 1; /* Firefox */
                  }
                  
                  :-ms-input-placeholder { /* Internet Explorer 10-11 */
                   color: white;
                   font-family: 'CaustenR';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 1rem;
                    line-height: 1rem;
                  }
                  
                  ::-ms-input-placeholder { /* Microsoft Edge */
                   color: white;
                   font-family: 'CaustenR';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 1rem;
                    line-height: 1rem;
                  }             
            }
        }
        &__heading{
            font-family: 'CaustenR';
            font-style: normal;
            font-weight: 700;
            font-size: 22px;
            line-height: 1.75rem;
        }
    }
}

.ShareQuiz__container__right::-webkit-scrollbar {
    width: 7px;
}

.ShareQuiz__container__right::-webkit-scrollbar-track {
    background-color: $qq-dark-blue;
}

.ShareQuiz__container__right::-webkit-scrollbar-thumb {
    background: $qq-blue-3; 
    border-radius: 7px;
}

.Member{
    display: flex;
    justify-content: space-between;
    margin-top:0.25rem;
    gap: 0.5rem;
    &__email, &__button{
        font-family: 'CaustenR';
        font-style: normal;
        font-weight: 500;
        font-size: 1rem;
        line-height: 2rem;
    }
    &__email{        
        max-width: 250px;     
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    &__button{
        color: $qq-yellow;
        &:hover {
            font-weight: 700;
            text-decoration-line: underline;
            cursor: pointer;
        }

    }
}

.SaveQuiz{
    &__btncontainer{
        display: flex;
        padding-top: 1rem;
        justify-content: space-evenly;
    }
    &__link{
        font-family: 'CaustenR';
        font-style: normal;
        font-weight: 700;
        font-size: 1.25rem;
        line-height: 1.625rem;
        align-items: center;
        text-align: center;
        color: $qq-yellow;
        margin-top: 3rem;
        &:hover {
            font-size: 1.375rem;
            text-decoration-line: underline;
            cursor: pointer;
        }
    }
}

.AssignQuiz{
    &__checkbox{
        flex-direction: row-reverse !important;
        align-items: center;
        justify-content: flex-end !important;        
        margin: 0.5rem;
        gap: 1rem;
        & > input {
            width: 1.25rem;
            cursor: pointer;
            accent-color: $qq-yellow;
            border: none;
            margin-bottom: unset;
        }

        & > label{
            margin-bottom: unset;
        }
    }
}



