@import "../../styles/abstracts/_variables.scss";

.signin{  
    &__title{
        font-family: "CaustenEB";
        font-size: 3.125rem;
        font-weight: 800;
        line-height: 3.125rem;
        text-align: center;
        margin-top: 2rem;
        @media screen and (max-width:$md) {
            font-size: 2rem;
            line-height: 2rem;            
        }            
    }
    &__subtitle{
        font-family: "CaustenR";
        font-size: clamp(0.9375rem, 3vw ,1.25rem);
        font-weight: 500;
        line-height: 1.25rem;
        text-align: center;
        margin-bottom: 2rem;
    }        
    &__icon{
        position: relative;
        top: -35%;
        left: 94%;
        cursor: pointer;
        color: $qq-yellow;
        @media screen and (max-width:$md) {
            left: 91%;           
        } 
    }         
    &__forgotPassword{
        color: $qq-yellow;
        font-family: "CaustenR";
        font-size: clamp(0.9375rem, 3vw ,1.25rem);
        font-weight: 600;
        line-height: 1.25rem;
        position: relative;
        top: -15%;            
    }  
    &__input{ //customclass for Input Component div
        width: 75%;
        @media screen and (max-width:$sm) {
            width: 85%;         
        }
    }           
    &__savebtn{
        display: flex;
        justify-content: center;
        margin-top:1%;
        margin-bottom:1.625rem;
        & > button{
            width: 17vw;
            @media screen and (max-width:$md) {
                width: 25vw;
            }
            @media screen and (max-width:$sm) {
                width: 45vw;
            }
        }
    }
    &__social{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        width: 75%; 
        margin-bottom: 2rem;   
        @media screen and (max-width:$sm) {
            width: 95%; 
        }    
        & > p{
            line-height: 2.825rem; // Height of __button
            font-family: "CaustenR";
            @media screen and (max-width:$sm) {
                font-size: 0.75rem;
                line-height: 1.625rem; // Height of __button
            }
        }
        &__button{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            border: 1px solid white;
            border-radius: 10px;
            padding: 0.75rem 1rem;
            vertical-align: middle;
            cursor: pointer; 
            background-color: white;
            @media screen and (max-width:$sm) {
                padding: 0.25rem 0.5rem;
                border-radius: 5px;
            }                       
            & > p{
                margin-left: 1rem;
                font-family: "CaustenR";
                font-weight: 600;
                color: black;
                @media screen and (max-width:$sm) {
                    font-size: 0.75rem;
                }
            }
            & > img {
                height: 1em;
                width: 1em;
            }
        }
    } 
}
.oneline{
    display: flex;
    justify-content: space-around;
}
.yellow{
    color: $qq-yellow;
    cursor: pointer;
}
.signin::-webkit-scrollbar {
    width: 7px;
}
.signin::-webkit-scrollbar-track {
    background-color: $qq-dark-green;
}
.signin::-webkit-scrollbar-thumb {
    background: $qq-green; 
    border-radius: 7px;
}