@import "./../../../styles/abstracts/variables";

.qtestMobileFixedHeader {
    width: 100%;
    position: fixed;
    top: 1px;
    padding-top: 90px;
    display: flex;
    background-color: $qq-blue;
    justify-content: center;
    @media screen and (min-width: $md) {
        padding-top: 100px;
    }
    
    &__container {
        height: 100%;
        @media screen and (max-width: $md) {
            width: 90%;
            background-color: $qq-blue;
            color: white;
            
            // transform: translateX(-50%);
            @media screen and (max-height: 800px) {
                // top: 12vh;
                // top: 10vh;
            }

        }
    }

    &__questioncount{
        padding-top: 1.1rem;
    }

}
