@import "../../styles/abstracts/_variables.scss";

.editprofile{
    display: flex;
    background-color: $qq-green;
    overflow-x: hidden;    
    height: 100vh; 
    width: 100%;
    flex-direction: column;
    color: white;   
    
    &__pic{
        display: flex;
        justify-content: center;

        &__img{
            margin-bottom: 2rem;
            cursor: pointer;
            height: 100px;
            width: 100px;
        }        
    }

    &__content{
        padding-left: 14%;
        padding-right: 14%;

        &__input{
            height: 7rem;
            ::placeholder{
                font-family: "CaustenR";
                font-size: 18px;
                line-height: 18px;
                color: #FFF;
            }
        }

        &__label{
            font-family: "CaustenR";
            font-size:  clamp(1rem, 3vw ,1.375rem);               
            font-weight: 700;
            letter-spacing: 0.0313rem;
            line-height: 1.375rem;
            margin-bottom: 1rem;
        }

        &__item{
            display: flex;
            flex-direction: column;
            height: 7rem;
            justify-content: space-around;

            & > label{
                font-family: "CaustenR";
                font-size:  clamp(1rem, 3vw ,1.375rem);
                font-weight: 700;
                letter-spacing: 0.0313rem;
                line-height: 1.375rem;
            }    
        }
    }

    &__savebtn{
        display: flex;
        justify-content: center;
        margin-top:1%;
        margin-bottom:3.625rem;
        & > button{
            width: 17vw;
            @media screen and (max-width:$md) {
                width: 30vw;
            }
        }
    }    
}

.editprofile::-webkit-scrollbar {
    width: 7px;
}

.editprofile::-webkit-scrollbar-track {
    background-color: #75b343;
}
.editprofile::-webkit-scrollbar-thumb {
    background: #4b9710; 
    border-radius: 7px;
}

.MuiOutlinedInput-root {
    padding: 5px !important;
}

.column{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}