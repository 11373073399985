@import "../../styles/abstracts/variables.scss";

.downloadCSVComponent {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #FDB913;
    padding: 2px;
    border-radius: 5px;
    width: 35px;
    height: 30px;    
    
    & .download_icon {
        color: #FFFFFF;
        font-size: x-large;
    }

    &:hover {
        background-color: #FFFFFF;
        & .download_icon {
            color: #FDB913;
        }
    }
    @media screen and (max-width: $sm) {
        width: 30px;
        height: 25px;
        & .download_icon {
            font-size: large;
        }
    }
}