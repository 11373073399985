@import "../../styles/abstracts/_variables.scss";

.profile{
    display: flex;
    background-color: $qq-green;
    overflow-x: hidden;    
    height: 100vh; 
    width: 100%;
    flex-direction: column;
    color: white;

    &__cancelModal{
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-around;
        @media screen and (max-width:$sm) {
            flex-direction: column;
            // justify-content: center; 
            width: unset;
            &>button{
                margin-bottom: 10px;
            }           
        }        
        
    }

    &__userinfo{
        margin-left: min(5%, 3rem);
        margin-top: min(5%, 3rem);
        margin-right: min(5%, 3rem);
        padding-bottom: min(17%, 3rem);
        display: flex;
        border-bottom: 2px solid lightgray ;        
        & > img{
            height: clamp(4.6875rem, 12vw ,10.5rem);
        }
        &__left{
            // padding-top: 1.5rem;
            display: flex;
            flex-direction: column;
            height: clamp(4.6875rem, 12vw ,10.5rem);
            font-family: "CaustenR";
            font-size: clamp(1.125rem, 3vw ,3.125rem);
            font-weight: 800;
            letter-spacing: 0.5008px;
            margin-left: 5%;            
            @media screen and (max-width: $sm) {
                font-size: 18px;
                font-weight: 700;
                letter-spacing: 0.5px;
                line-height: 18px;               
            }
            &__name, &__about{
                display: flex;
                flex-direction: column;
                justify-content: center;
                height: 50%;
            }
            & > button{
                padding: 4px;
                margin-top: 10px;
                width: 80%;
                border-radius: 5px;
                outline: none;
                border: none;
                background-color: #cc1010;
                color: white;
            }
        }
    }

    &__quizstats{
        font-family: "Causten Round";
        font-size: clamp(15px,2.5vw,28px);
        font-weight: 500;
        line-height: clamp(25px,4vw,45px);
        margin:  min(5%, 3rem);
        padding-bottom: min(5%, 3rem);
        border-bottom: 2px solid lightgray;

        &__item{
            display: flex;
            justify-content: space-between;
        }
    }    
}

.text{
    font-family: "CaustenM";
    font-size: clamp(1rem,2vw,2rem);
    font-weight: 500;
}

.subtext{
    font-family: "CaustenM";
    font-size: clamp(0.5rem,1vw,1rem);
    font-weight: 500;
}