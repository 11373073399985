@import "../../../styles/abstracts/variables";

.createUser {
  background-color: $qq-dark-green;
  min-height: 100%;
  color: white;
  font-family: "CaustenEB";
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  align-items: center;
  
  &__inputGrid {
    display: grid;
    margin: 0rem auto 2rem auto;
    row-gap: 2rem;
    column-gap: 8rem;
    grid-template-columns: repeat(1, 1fr);
    justify-content: center;
  
    @media (min-width: 900px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &__input {
    width: 40%;
    @media screen and (max-width: $md) {
      width: 75%;
    }
  }
  &__userTypeRadio {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-bottom: 1.2rem;
    & > select {
      background-color: transparent;
      height: 2.8rem;
      padding: 0.2rem 0.4rem;
      margin-top: 0.8rem;
      border-radius: 8px;
      outline: none;
      border: 2px solid white;
      color: white;
      font-family: "CaustenR";
      font-size: 1.2rem;
    }
    & > label {
      font-family: "CaustenR";
      font-size: clamp(1rem, 3vw, 1.375rem);
      font-weight: 700;
      letter-spacing: 0.0313rem;
      line-height: 1.375rem;
    }
  }
}
