@import "./../../../styles/abstracts/variables";

.qtestfooter {
  position: fixed;
  bottom: 0;
  display: flex;
  flex-direction: column;
  background-color: $qq-blue;
 

  &__iconContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    // margin: 25px;
    margin: clamp(0.5rem, 2vh, 1.5rem);
    & > p {
      color: white;
      font-size: clamp(1rem, 4vmin, 3rem);
      font-family: "CaustenSB";
    }
  }

}