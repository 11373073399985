@import "../../styles/abstracts/_variables.scss";

.waitingroom {
  // height: 100vh;
  min-height: 100vh;
  background-color: #75b543;
  color: white;
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: $sm) {
    min-height: 100%;
    height: auto;
  }
  @media only screen and (max-height: 756px) { //if you change max-height also change it for the BOX in js file
    min-height: 100%;
    height: auto;
  }

  & > h3 {
    text-align: center;
  }

  &__hostActions {
    padding: 0 5%;
  }

  &__joinButton {
    width: 3.8rem;
    height: 3.5rem;
    min-width: 0 !important;
    padding: 0 !important;
    @media only screen and (max-width: $md) {
      width: 2.5rem;
      height: 2.5rem;
    }
  }

  &__actionContainer {
    display: flex;
    justify-content: space-between;
    padding-top: 8rem;
    @media only screen and (max-width: $sm) {
      justify-content: center;
    }

    & > h1 {
      font-size: 2.5rem;
      font-family: "CaustenEB";
      @media only screen and (max-width: $sm) {
        font-size: 2rem;
      }
    }
  }
  &__infoContainer {
    display: flex;
    flex-direction: row;
    column-gap: 6rem;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem 0;

    &__infoText {
      margin: 0;
      font-size: 1rem;
      @media only screen and (max-width: $md) {
        font-size: 0.875rem;
      }
    }

    &__code {
      font-size: 1.5rem;
      &__text {
        font-size: 4rem;
        letter-spacing: 0.75rem;
        font-family: "CaustenB";
      }
    }
    @media only screen and (max-width: $md) {
      flex-direction: column; 
      align-items: flex-start; 
      &__code {
        margin-top: 0.5rem;
        font-size: 1rem;
        &__text {
          letter-spacing: 0.75rem;
          font-size: 2rem;
        }
      }
    }
  }
  &__playerQRContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    @media only screen and (max-width: $md) {
      flex-direction: column;
    }

    &__QRSection{
      margin-left: 8rem;
      
      @media only screen and (max-width: $md) {
        margin-left: 0rem;
      }
    }
  }
  &__numberOfPlayers {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #4B9710;
    width: 7rem;
    height: 3rem;
    padding: 1rem;
    border-radius: 2rem;
    gap: 1.8rem;
    & > .playersIcon{
      position: relative;
      top: 0.22rem;
      @media only screen and (max-width: $sm) {
        display: none;
      }
    }
    & > .playersNumber{
      font-weight:700;
      font-size:1.2rem;
      @media only screen and (max-width: $sm) {
        font-weight:600;
        font-size:1rem;
      }
    }
    @media only screen and (max-width: $sm) {
      width: 2.5rem;
      height: 2.5rem;
    }
  }
  &__linkAndShare {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-top: 1rem;
    @media only screen and (max-width: $sm) {
      height: 2.5rem;
      align-items: normal;
    }
  }
  &__link {
    display: flex;
    gap: 2.5rem;
    justify-content: center;
    background-color: #4b9710;
    align-items: center;
    padding: 0.8rem 1rem;
    border-radius: 10px;
    & > p:first-child {
      color: white;
      font-family: "CaustenM";
      font-size: 1.75rem;
      letter-spacing: 0.0063rem;
      line-height: 2.125rem;
      @media only screen and (max-width: $sm) {
        width: 6rem;
        overflow-x: auto;
        flex-grow: 1;
        font-size: 0.9375rem;
        line-height: 0.9375rem;
        &::-webkit-scrollbar {
          height: 0px;
        }
      }
    }

    @media only screen and (max-width: $sm) {
      font-size: 0.8rem;
      font-family: "CaustenM";
      gap: 0;
      flex-grow: 1;
    }
  }
  &__copyButton {
    color: $qq-yellow;
    margin-left: "auto";
    font-family: "CaustenSB";
    font-size: 1.4375rem;
    letter-spacing: 0.0425rem;
    line-height: 1.4375rem;
    @media only screen and (max-width: $sm) {
      font-size: 0.875rem;
      line-height: 1rem;
    }
  }
  &__players {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-top: 3rem;
  }
  &__playerContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;    
  }
  &__player{
    text-align: center;
    font-size: 1.5rem;
    letter-spacing: 0.08px;
    line-height: 1.875rem;
    font-family: "CaustenM";
    width: 6rem;
    white-space: nowrap;
    // word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    @media only screen and (max-width: $sm) {
      font-size: 0.75rem;
      line-height: 0.875rem;
      letter-spacing: 0.06px;
      width: 4rem;
    }
  }
  &__playerAvatar {
    border-radius: 8px;
    & > img {
      height: clamp(4.9375rem, 5.5vw, 3.75rem);
      width: clamp(4.9375rem, 5.5vw, 3.75rem);
    }
  }
  &__buttonContainer {
    display: flex;
    justify-content: center;
    gap: 3rem;
    margin-top: auto;
    padding-bottom: 2rem;
    @media only screen and (max-width: $sm) {
      flex-direction: column;
      align-items: center;
      gap: 1rem;
    }
  }
  &__button {
    border: 1px solid #fff;
    height: 3rem;
    width: 15rem;
    color: white;
    &:hover {
      background-color: $qq-yellow;
      color: white;
      border-color: $qq-yellow;
    }
  }
  &__noPlayers {
    display: flex;
    flex-direction: column;
    align-items: center;
    // margin-top: 3rem;
    margin-bottom: 2rem;
    gap: 2rem;

    & > img {
      height: 7rem;
      object-fit: contain;
    }
    & > p {
      font-family: "CaustenM";
    }
  }

  &__hostplayer{
    color: $qq-yellow;
    font-family: "CaustenM";
    font-size: 1rem;
    line-height: 1rem;
    text-align: center;
  }
  
  &__modal {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    width: 100%;

    & > h1 {
      font-size: 4.375rem;
      font-family: "CaustenEB";
      letter-spacing: 0.015rem;
      line-height: 4.375rem;
      text-align: center;

      @media only screen and (max-width: $sm) {
        font-size: 2rem;
        line-height: 2rem;
      }
      @media only screen and (max-width: $xl) {
        font-size: 2.5rem;
        line-height: 2.5rem;
      }
    }
    & > h2 {
      font-size: 2.375rem;
      font-family: "CaustenB";
      letter-spacing: 0.015rem;
      line-height: 4.375rem;
      text-align: center;

      @media only screen and (max-width: $md) {
        font-size: 1.75rem;
        line-height: 2rem;
      }
      & > span {
        color: $qq-green-3;
        letter-spacing: 1rem;
        font-size: 4.375rem;
        font-family: "CaustenEB";
        @media only screen and (max-width: $md) {
          font-size: 2rem;
        }
      }
    }
  }

  &__modalButtonContainer {
    display: flex;
    align-items: center;
    gap: 2rem;
    @media only screen and (max-width: $sm) {
      flex-direction: column;
      gap: 1rem;
    }
  }

  &__modalButton {
    height: 4.6875rem;
    width: 14.75rem;
    background-color: #30b2e7;
    box-shadow: none !important;
    border-radius: 10px !important;
    font-family: "CaustenR" !important;
    font-size: 1.75rem !important;
    font-weight: 700 !important; 
    letter-spacing: 0.0512rem !important;
    line-height: 1.75rem !important;

    @media only screen and (max-width: $sm) {
      height: 3rem;
      width: 7.375rem;
      font-size: 1rem !important;
    }
  }
}
