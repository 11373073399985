@import "../../styles/abstracts/variables.scss";

.header {
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 100%;
  top: 0;
  justify-content: center;
  padding: 1rem;
  border-bottom: 1px solid lightgrey;
  background-color: $qq-yellow;
  z-index: 1300;
  // min-height: 4rem;
  transform: skewY(-0.8deg);
  transform-origin: top left;
  @media only screen and (max-width: $md) {
    transform: skewY(-1.8deg);
  }
  @media only screen and (min-width: $md) {
    padding: clamp(0.5rem, 2vh, 1rem);
  }

  &__container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    transform: skewY(0.8deg);
    @media only screen and (max-width: $md) {
      transform: skewY(1.8deg);
      margin-top: 1rem;
    }
  }

  

  &__dataContainer {
    display: flex;
    gap: 1rem;
    & > p {
      padding: 0.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      border: 1px solid gray;
    }
  }

  &__logo {
    cursor: pointer;
    height: 4.2rem;
    object-fit: contain;
    transform-origin: top left;
    position: relative;
    @media only screen and (max-width: $md) {
      margin-top: -0.5rem;
      height: 2.5rem;
      transform: scale(1.2);
    }
    @media only screen and (max-width: 320px) {
      transform: scale(1.15);
    }
    @media only screen and (min-width: $md) {
      margin-left: 2%;
      height: clamp(3rem, 8vh, 4.2rem);
      
    }
  }

  &__beta {
    position: absolute;
    top: 0rem;
    left: 0rem;
    font-size: 0.75rem;
    color: #ffffff;
    font-family: "CaustenB";
    @media only screen and (max-width: $md) {
      top: -0.5rem;
      left: 0rem;
    }
  }

  &__actions {
    display: flex;
    margin-right: 3rem;
    gap: 1rem;
    & > h3 {
      color: #FFF;
      font-family: "CaustenSB";
      font-size: 24px;
      letter-spacing: 0.4px;
      line-height: 24px;
      text-align: center;
    }
    @media only screen and (max-width: $md) {
      display: none;
    }
    &__buttons{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 17px 0px;
      width: 9.625rem;
      gap: 8px;      
      color: #FFF;
      border-radius: 4rem;
      border: 0.125rem solid #FFFFFF;
      font-family: 'CaustenR';
      font-style: normal;
      font-weight: 700;
      font-size: 1rem;
      line-height: 0.6875rem;
      cursor: pointer;

      &__two{
        background-color: $qq-blue;
        border-color: $qq-blue;
      }

      &__three{
        &:hover {
          background-color: $qq-blue;
          border-color: $qq-blue;
        }
      }
    }
  }

  &__mobileDropdown {
    display: none;
    margin-right: 0.7rem;
    color: white;
    @media only screen and (max-width: $md) {
      display: block;
    }
  }

  &__menu {
    transform: skewY(1.8deg);
    list-style-type: none;
    padding-left: 0;
    margin-top: 2rem;
    & > li {
      margin-top: 1rem;
      color: white;
      font-family: "CaustenB";
      font-weight: 300;
      font-size: 1.3rem;
      display: flex;
      align-items: center;
      gap: 1.2rem;
    }
  }

  &__button {
    border-radius: 50%;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid lightgrey;
    cursor: pointer;
    box-shadow: -3px 7px 20px -5px rgba(0, 0, 0, 0.7);
    -webkit-box-shadow: -3px 7px 20px -5px rgba(0, 0, 0, 0.7);
    -moz-box-shadow: -3px 7px 20px -5px rgba(0, 0, 0, 0.7);
    &:hover {
      box-shadow: 0px 0px 0px 0px;
    }
  }
}
