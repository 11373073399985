@import "./../../../styles/abstracts/variables";

.quizMCQOptions {
    @media only screen and (max-width: $md) {
        padding-bottom: 2rem;
    }
    
    &__button {
        text-align: left;
        // width: 100%;
        // height: 100%;
        width: 100%;
        height: 100%;
        padding-top: 12px;
        padding-bottom: 12px;
        background-color: transparent;
        color: #FFF;
        border: 2px solid #FFF;
        border-radius: 10px;
        font-family: "CaustenM";
        font-size: 28px;
        // font-size: 28px; // Added by sani
        
        letter-spacing: 0.1px;
        
        margin-top: 10px;
        
        &:hover {
            background-color: #FFF;
            color: #30B2E7;
        }
        
        @media only screen and (min-width: $md) {
            font-size: clamp(1rem, 3vmin, 3rem);
        }

        @media only screen and (max-width: $md) {
            font-size: 15px;
            letter-spacing: 0.05px;
            padding: unset;
            padding-left: 0.5rem;
        }
        @media only screen and (max-width: $md) and (min-height: $md) {
            font-size: 30px;
            letter-spacing: 0.05px;
        }
        @media only screen and (min-width: $md) and (max-height: $md) {
            padding-top: 6px;
            padding-bottom: 6px;
        }
        @media only screen and (min-width: $md) and (max-height: $sm) {
            padding-top: 0;
            padding-bottom: 0;
        }
        // @media only screen and (max-width: 1440px) {
        //     // Added By Sani
        //     font-size: 20px;
        //     height: 60px;
        //     width: 380px;
        // }
        // @media only screen and (max-width: 1366px) {
        //     // Added By Sani
        //     font-size: 18px;
        //     height: 54px;
        //     width: 100%;
        // }
        // @media only screen and (max-width: 1024px) {
        //     // Added By Sani
        //     font-size: 18px;
        //     height: 64px;
        //     width: 100%;
        // }

        // @media only screen and (max-width: 1024px) and (max-height:600px) {
        //     font-size: 16px;
        //     height: 54px;
        //     width: 100%;
        // }

        // // @media only screen and (max-width: 768px) {
        // //     // Added By Sani
        // //     font-size: 18px;
        // //     height: 54px;
        // //     width: 100%;
        // // }

        // @media only screen and (max-width: 680px) {
        //     // Added By Sani
        //     font-size: 18px;
        //     height: 54px;
        //     width: 100%;
        // }

        // @media screen and (max-width: $sm) {
        //     font-family: "CaustenR";
        //     font-size: 15px;
        //     letter-spacing: 0.05px;
        //     padding-top: 10px;
        //     padding-bottom: 10px;
        // }
        // @media screen and (max-width: 414px) {
        //     height: 40px;
        //     width: 100%;
        // }
    }
}