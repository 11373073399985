@import "../../../styles/abstracts/_variables.scss";

.nameEmail {
  background-color: #75B543;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  justify-content: center;

  &__image {
    width: 18rem;

    @media screen and (max-width:$md) {
      width: 15rem;
    }  

    @media screen and (max-width:$sm) {
      width: 10rem;
    }  

  }

  &__details {
    display: flex;
    align-content: center;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 1rem;
    margin-bottom: 1.5rem;
    width: 100%;

    @media screen and (max-width:$md) {
      // width: 80%;
    }   

    & > label {
      font-family: "CaustenR";
      font-size: 1.5625rem;
      font-size: clamp(0.9375rem, 2vw, 1.5625rem);
      letter-spacing: 0.0462rem;
      // line-height: clamp(0.9375rem , 2vw, 1.5625rem);
      // line-height: 1.5625rem;
      @media screen and (max-width:$sm) {
        // font-size: 0.9375rem;
        font-weight: 700;
        // line-height: 0.9375rem;          
      }
    }
    & > input {
      background-color: transparent;
      // margin-top: 0.8rem;
      border-radius: 8px;
      outline: none;
      border: 2px solid white;
      height: 2.8rem;
      color: white;
      padding: 0.2rem 0.5rem;
      font-family: "CaustenR";
      font-size: 1.2rem;
      width: 85%;

      @media screen and (max-width:$md) {
        // min-width: 20rem;
      }
    }      
  }

  &__description {
    padding: 1rem 0 1rem 0;
    font-size: xx-large;
    font-family: "CaustenR";
    text-align: center;

    @media screen and (max-width:$md) {
      padding: 1rem 2rem 1rem 2rem;
    }
  }

  &__button {
      margin-top: 2rem;
      @media screen and (max-width:$md) {
        // margin-top: 2rem;
      }
      color: white !important;      
  }
  
}