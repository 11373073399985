@import "../../styles/abstracts/variables";
.quizTile{
    position: relative;
    height: 100%;
    
    &__tile{
        cursor: pointer;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        padding: 1.8rem;
        border: 2px solid white;
        border-radius: 10px;
        gap: 0.5rem;
        & > label {
            font-size: 2rem;
            letter-spacing: 0.0069rem;
            text-align: center;
            font-family: "CaustenM";
            margin-top: 3rem;
            @media screen and (max-width: $lg) {
            font-size: clamp(1rem, 2.3vw, 1.5rem);
            line-height: 1.5rem;
            }
            @media screen and (max-width: $md) {
            font-size: 1rem;
            line-height: 1rem;
            }
        }
    }

    &__icon{
        position: absolute;
        top:1rem;
        height: 2rem;
        width: 2rem;
        @media screen and (max-width: $md) {
            top:2.5rem;
            height: 1rem;
            width: 1rem;
        }
        @media screen and (min-width: $md) {
            right:2rem;
        }
        color: $qq-yellow;
    }
    
}
    
