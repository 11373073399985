@import "./../../../styles/abstracts/variables";

.quizComponent {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow: hidden;
    height: 100vh; 
    height: 100dvh; //for safari 15.4+, mozilla android 101+
        
    &__Content {
        height: 100%;
        flex-grow: 1;
        overflow-y: auto;
        justify-items: center;
        align-items: start;
        background-color: $qq-blue;
        position: relative;
        transition: width 0.2s ease-out;
        @media screen and (min-width: $md) {
            padding-bottom: 90px;
        }
        @media screen and (min-width: $md) and (min-height: $md) {
            padding-bottom: 120px;
        }
        @media only screen and (min-width: $md) {
            //for firefox
            scrollbar-color: #007fb3 #30b2e7;
        }
    }

    &__Content::-webkit-scrollbar {
        @media only screen and (min-width: $md) {
            width: 7px;
        }
    }

    &__Content::-webkit-scrollbar-track {
        @media only screen and (min-width: $md) {
            background-color: rgba($color: #000000, $alpha: 0.0);
        }
    }

    /* Handle */
    &__Content::-webkit-scrollbar-thumb {
        @media only screen and (min-width: $md) {
            background: #007fb3;
            border-radius: 7px;
        }
    }
}

.quizComponent__nextBtnFooter {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    @media screen and (max-width: 899px) {
        position: fixed;
        bottom: 0;
        height: 100px;
        width: 100%;
        background-color: #007fb3;
        padding: 0 1rem 0 1rem;
    }
}
