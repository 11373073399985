@import "./../../../styles/abstracts/variables";

.quizFIB {
    display: flex;
    flex-direction: row;
    row-gap: 2rem;
    column-gap: 6rem;
    justify-content: center;
    @media screen and (max-width: $md) {
        flex-direction: column;
        justify-content: center;           
    }

    &__submitButton {
        @media screen and (max-width: $md) {
            padding-bottom: 1rem;
            display: flex;
            justify-content: center;         
        }
    }

    &__blanks{
        display: flex;
        justify-content: flex-start;
        flex-wrap: nowrap;
        gap: 1rem;
        padding-top: 0.75rem;

        // display: grid;
        // justify-content: center;
        // grid-template-columns: repeat(auto-fill, clamp(2rem, 8vw, 5rem));
        // grid-gap: 1rem;
        
        // align-content: center;
        // grid-auto-flow: column;

        @media screen and (max-width: $md) {
            width: 100%;   
            margin-top: 0.1rem;
            margin-bottom: 0.1rem; 
            justify-content: center;
            // grid-gap: 6%;     
        }
    }

    & > button{
        // height: clamp(2.5rem,8vh,4.5625rem);
        padding: 0;
        @media screen and (min-width: $md) {
            max-width:20%;
            min-width: 10.5rem;
            align-self: flex-end;
            height: 3.5rem;
        }        
        @media screen and (max-width: $md) {
            width: 70%;    
            max-width: 10.5rem;
            padding: 1rem 0;
            margin: auto;
        }
    }
    
}