@import "../../styles/abstracts/_variables.scss";

.signup{
    &__title{
        font-family: "CaustenEB";
        font-size: 3.125rem;
        font-weight: 800;
        line-height: 3.125rem;
        text-align: center;
        padding-top: 20%;
        @media screen and (max-width:450px) {
            padding-top: 15%;
        }
        @media screen and (max-width:$md) {
            font-size: 2rem;
            line-height: 2rem;            
        }            
        @media screen and (max-width:$sm) {
            margin-top: min(5%,3rem);
        }
    }
    &__subtitle{
        font-family: "CaustenR";
        font-size: clamp(0.9375rem, 3vw ,1.25rem);
        font-weight: 500;
        line-height: 1.25rem;
        text-align: center;
        margin-bottom: 1rem;
    }        
    &__icon{
        position: relative;
        top: -40%;
        left: 94%;
        @media screen and (max-width:$md) {
            left: 91%;           
        } 
    }         
    &__input{ //customclass for Input Component div
        width: 75%;
        @media screen and (max-width:$sm) {
            width: 85%;         
        }
    }           
    &__savebtn{
        display: flex;
        justify-content: center;
        margin-top:1%;
        margin-bottom:1.625rem;
        & > button{
            width: 17vw;
            @media screen and (max-width:$md) {
                width: 25vw;
            }
            @media screen and (max-width:$sm) {
                width: 45vw;
            }
        }
    } 
    &__ageContainer {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        margin-bottom: 1.2rem;
        width: 75%; 
        @media screen and (max-width:$sm) {
          width: 85%;
        }   
        & > select {
            background-color: transparent;
            height: 2.8rem;
            padding: 0.2rem 0.4rem;
            margin-top: 0.8rem;
            border-radius: 8px;
            outline: none;
            border: 2px solid white;
            color: white;
            font-family: "CaustenR";
            font-size: 1.2rem;
          }
        & > label {
            font-family: "CaustenR";
            font-size:  clamp(1rem, 3vw ,1.375rem);               
            font-weight: 700;
            letter-spacing: 0.0313rem;
            line-height: 1.375rem;
          }
    }
}
.oneline{
    display: flex;
    justify-content: space-around;
}
.yellow{
    color: $qq-yellow;
}
.pointer{
    cursor: pointer;
    color: $qq-yellow;
}
.signup::-webkit-scrollbar {
    width: 7px;
}
.signup::-webkit-scrollbar-track {
    background-color: $qq-dark-green;
}
.signup::-webkit-scrollbar-thumb {
    background: $qq-green; 
    border-radius: 7px;
}