@import "../../styles/abstracts/variables";

.report .titleComponent{
    padding-top: unset;

    &__backArrow{
        left: 0;
        top: 11px;
    }
}

.report .MuiTypography-root{
    white-space: pre-line;
    font-family: 'CaustenR';
    font-style: normal;
    font-weight: 800;
    font-size: 3.125rem;
    line-height: 3.125rem;
    text-align: center;
}

.report{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;

    &__subtitle{
        font-family: 'CaustenR';
        font-style: normal;
        font-weight: 400;
        font-size: 1.25rem;
        line-height: 1.5625rem;
        text-align: center;
        color: #FFFFFF;
        margin-top: 1rem;
        margin-bottom: 2rem;
    }
    &__or{
        font-weight: 800;
        margin-top: unset;
        margin-bottom: unset;
    }
    &__button{
        display: flex;
        justify-content: center;
        margin-top: 2rem;
        margin-bottom: 2rem;
    }
    &__grid{
        display: flex;
        overflow-y: auto;
        justify-content: center;
        margin-bottom: 7rem;
        width: 100%;
        flex-grow: 1;
        //for firefox
        scrollbar-color: $qq-green-3 $qq-dark-green;
        @media only screen and (min-width: $md) {
            //for firefox
            scrollbar-color: $qq-green-3 $qq-dark-green;
        }
    }
    &__grid::-webkit-scrollbar {
        width: 7px;
        @media only screen and (min-width: $md) {
            width: 7px;
        }
    }

    &__grid::-webkit-scrollbar-track {
        background-color: transparent;
        @media only screen and (min-width: $md) {
            background-color: transparent;
        }
    }

    /* Handle */
    &__grid::-webkit-scrollbar-thumb {
        background: $qq-green-3;
        border-radius: 7px;
        @media only screen and (min-width: $md) {
            background: $qq-green-3;
            border-radius: 7px;
        }
    }
    &__proceedButton{
        display: flex;
        justify-content: center;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        margin-left: -20%;
        padding-left: 10%;
        width: 100%;
        background-color: $qq-green-3;  
        position: fixed;
        bottom: 0rem;
        & > button {
            min-width: 14rem;
        }
    }

    &__reportType{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 2rem;
        width: 80%;
        margin-left: 10%;
        flex-wrap: wrap;
        &__button{
            display: flex;
            justify-content: center;
            align-items: center;
            background: transparent;
            border: 2px solid #FFFFFF;
            border-radius: 64px;
            font-family: 'CaustenR';
            font-style: normal;
            font-weight: 700;
            font-size: 1rem;
            line-height: 1rem;
            padding: 1.25rem 1.875rem 1.25rem 1.875rem;
            white-space: nowrap;
        }
    }
}

.report__grid .horizonatalTile__description{
    p:nth-child(3){
        color: $qq-green-3;
        font-weight: 800;
    }
}

.report__activeTile{
    color: #FFF !important;
    & > div{
        background-color: $qq-green-3;
       
        & > p{
            color: #FFF !important;
        }
    }
}

.report__activeBtn{
    background-color: $qq-yellow;
    border: none;
}

.reportPopUp{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    width: 90%;
    margin-left: 5%;
    text-align: center;
    & > p {
        margin-top: 0.8125rem;
        margin-bottom: 16px;
        font-family: 'CaustenR';
        font-style: normal;
        font-weight: 400;
        font-size: 1.25rem;
        line-height: 1.5rem;
        text-align: center;
    }
    & > button{
        margin-top: 16px;
        width: 80%;
        margin-left: 10%;
    }
}
