@import "../../../styles/abstracts/variables.scss"; 
.answerBlanks{
    border: none;
    max-width: 4rem;
    // width:clamp(1rem,8vw,4rem);
    width:clamp(0.5rem,4vw,4rem);
    text-align: center;
    background-color: transparent;
    border-bottom: 0.1563rem solid white;
    font-family: "CaustenR" ;
    // font-size: clamp(1.3rem,3vw,1.75rem);
    // font-size: clamp(0.75rem,1.5vw,1.75rem);
    font-size: clamp(1rem,1.5vw,1.75rem);
    letter-spacing: 0.0063rem;
    line-height: 1.75rem;    
    color: #FFF;
    // height: clamp(2.5rem,8vh,4.5625rem); 
    height: clamp(1.5rem, 4vh,4.5625rem); 
    caret-color: transparent;
    &:focus{
        outline: none;
        border-bottom: 0.1563rem solid $qq-yellow;
    }
    &:disabled{
        border: none;
    }

    @media only screen and (max-width: $md) {
        width: 100%;
        border-radius: 0;
        opacity: 1;
        padding-left: 0;
        padding-right: 0;
    }
}