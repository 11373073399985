@import "../../../../styles/abstracts/_variables.scss";

.audioMedia {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

audio::-webkit-media-controls-panel{
    background-color: #FDB913;
}
audio::-webkit-media-controls-mute-button{
    background-color: #5fc4ec;
    border-radius: 50%;
}
audio::-webkit-media-controls-mute-button:hover{
    background-color: #30B2E7;
}
audio::-webkit-media-controls-play-button{
    background-color: #5fc4ec;
    border-radius: 50%;
}
audio::-webkit-media-controls-play-button:hover{
    background-color: #30B2E7;
}
audio::-webkit-media-controls-current-time-display{
    color: #FFFFFF;
}
audio::-webkit-media-controls-time-remaining-display{
    color: #FFFFFF;
}
// audio::-webkit-media-controls-timeline-container{
//     // background-color: #FDB913;
// }
// audio::-webkit-media-controls-timeline{
//     // background-color: #FDB913;
// }
// audio::-webkit-media-controls-volume-slider-container{
//     // color: #FDB913;
// }
// audio::-webkit-media-controls-volume-slider{
//     // color: #FDB913;
// }
// audio::-webkit-media-controls-seek-back-button{
//     background-color: #FDB913;
// }
// audio::-webkit-media-controls-seek-forward-button{
//     background-color: #FDB913;
// }
// audio::-webkit-media-controls-fullscreen-button{
//     background-color: #FDB913;
// }
// audio::-webkit-media-controls-rewind-button{
//     background-color: #FDB913;
// }
// audio::-webkit-media-controls-return-to-realtime-button{
//     background-color: #FDB913;
// }
// audio::-webkit-media-controls-toggle-closed-captions-button{
//     background-color: #FDB913;
// }