@import "../../styles/abstracts/_variables.scss";

.tilesCarouselComponent {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 1rem 0;
    background-color: $qq-green-3;
    border-radius: 15px;
    padding: 1rem 1.5rem 1.5rem 1rem;

    &__header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.5rem 1.5rem 1.5rem 1.5rem;
        // background-color: $qq-dark-green;
        // color: $qq-white;
        font-size: 1.5rem;
        font-weight: 700;

        &__viewAll {
            cursor: pointer;
            font-size: 1rem;
            color: $qq-yellow;
            display: flex;
            gap: 2rem;
            align-items: center;
            min-width: 200px;
            justify-content: space-around;
            font-family: 'CaustenR';
            font-style: normal;
            font-weight: 800;
            line-height: 26px;
        }

        &__buttons {
            display: flex;
            gap: 1rem;
            flex-grow: 1;
            justify-content: space-around;
        }
    }

    &__items {
        display: flex;
        width: 100%;
        overflow-x: scroll;
        padding: 0 1rem;
        &::-webkit-scrollbar {
            display: none;
        }

        &__noData {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 1.25rem;
            font-weight: 400;
            font-family: 'CaustenR';
            padding: 1.5rem;
            // color: $qq-white;
        }
    }

    &__item {
        width: 30%;
        margin: 0.5rem 1rem;
        min-width: 350px;
        // min-height: 100px;
    }

}