@import "../../styles/abstracts/_variables.scss";

.dashboard {
    display: flex;
    flex-direction: row;
    background-color: $qq-dark-green;
    overflow-x: hidden;
    height: 100vh;
    width: 100%;
    color: white;
    padding-top: 7vh;
    justify-content: center;
    align-items: flex-start;

    &__content {
        display: flex;
        flex-direction: column;
        width: 70%;
        justify-content: center;
        font-family: "CaustenR";
        align-items: center;
        margin-left: 7%;
        padding-top: inherit;
    }

    &__body{
        display: flex;
        width: 100%;
        flex-direction: column;
        margin-top: 1rem;
    }

    &__header {
        display: flex;
        flex-direction: row;
        background-color: $qq-yellow;
        border-radius: 10px;
        align-items: center;
        padding: 1rem;
        font-family: 'CaustenR';
        font-size: 4.5vh;
        line-height: 30px;
        width: 100%;
        color: white; 

        &__description {
            margin: 1rem 0;
            font-size: 1.5rem;
            line-height: 26px;
        }

        &__button {
            width: 17vw;
            // align-self: center;
            background: #30B2E7;
            color: white;
            height: 50px;
            border-radius: 10px;
            border: none;
            // margin: 0.5rem;
            cursor: pointer;
            font-family: 'CaustenR';
            font-style: normal;
            font-weight: 700;
            font-size: 3vh;
            line-height: 26px;
            text-transform: uppercase;
        }

        &__welcomebanner{

            display: flex;
            flex-direction: column;
            flex-grow: 1;
            justify-content: space-around;
            height: 100%;
            &__image{
                // height: auto;
                // max-width: 400px;
                // max-height: 300px;
             }

        }

        
    }
}

.createNewQuiz {
    display: flex;
    flex-direction: column;
    position: relative;

    &__subtitle {
        font-family: 'CaustenR';
        font-style: normal;
        font-weight: 400;
        font-size: 1.5625rem;
        line-height: 1.875rem;
        text-align: center;
        margin-top: 0.625rem;
        margin-bottom: 3.0625rem;
        text-align: center;
    }

    &__options {
        display: flex;
        width: calc(100% + 64px);
        transform: translateX(-32px);
        flex-grow: 1;
        margin-bottom: -32px;

        &__title {
            font-family: 'CaustenR';
            font-style: normal;
            font-weight: 800;
            font-size: 2rem;
            line-height: 2rem;
            text-align: center;
            text-transform: capitalize;
        }

        &__description {
            font-family: 'CaustenR';
            font-style: normal;
            font-weight: 400;
            font-size: 1.25rem;
            line-height: 1.5rem;
            text-align: center;
            margin-top: 1rem;
        }

        &__left,
        &__right {
            padding-top: 1rem;
            padding-right: 0.5rem;
            flex-grow: 1;
            flex-basis: 0;
            height: 10rem;
            text-align: center;
            cursor: pointer;
            height: 20rem;
            -ms-transform: skew(-10deg, 0deg);
            -webkit-transform: skew(-10deg, 0deg);
            transform: skew(-10deg, 0deg);

            &>p {
                -ms-transform: skew(10deg, 0deg);
                -webkit-transform: skew(10deg, 0deg);
                transform: skew(10deg, 0deg);
            }
        }

        &__left {
            background-color: $qq-green-3;
            margin-left: -0.25rem;
        }

        &__right {
            background-color: $qq-dark-green;
            padding-left: 1rem;
            padding-right: 1rem;
            color: $grey-1;
        }
    }

    &__options:after {
        content: " ";
        position: absolute;
        width: 50%;
        height: 100%;
        z-index: -1;
        background-color: $qq-dark-green;
        right: 0;
    }

    &__options:before {
        content: " ";
        position: absolute;
        width: 50%;
        height: 100%;
        z-index: -1;
        background-color: $qq-green-3;
    }
}

.quizDetails {
    display: flex;

    &__details {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        padding-right: 2rem;

        &__timelimit {
            display: flex;
            align-items: center;
            padding-bottom: 1rem;

            &>label {
                font-family: "CaustenR";
                font-size: clamp(1rem, 3vw, 1.375rem);
                font-weight: 700;
                letter-spacing: 0.0313rem;
                line-height: 1.375rem;
                margin-right: 1rem;
            }
        }
    }

    &__bannerUpload {
        display: flex;
        flex-direction: column;
        width: 20%;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &>img {
            width: 100%;
        }
    }

    &__proceedbtn {
        display: flex;
        justify-content: center;
        padding-top: 1rem;
    }
}

.attachtags {
    margin-top: 2rem;
}