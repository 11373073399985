@import "/../../styles/abstracts/_variables.scss";

.getreadyroom {
  height: 100vh;
  background-color: #75b543;
  color: white;
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: $sm) {
    min-height: 100%;
    height: auto;
  }
  @media only screen and (max-height: 756px) { //if you change max-height also change it for the BOX in js file
    min-height: 100%;
    height: auto;
  }
  &__buttonContainer {
    display: flex;
    justify-content: center;
    gap: 3rem;
    margin-top: auto;
    margin-bottom: 4rem;
    padding-bottom: 2rem;
    @media only screen and (max-width: $sm) {
      flex-direction: column;
      align-items: center;
      gap: 1rem;
    }
  }
  &__countDown {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 3rem;
    margin-bottom: 3rem;
    gap: 2rem;

    & > img {
      height: 7rem;
      object-fit: contain;
      @media only screen and (max-width: $sm) {
        height: 4rem;
      }
    }
    & > p {
      font-family: "CaustenM";
    }
  }
  &__countDownText {
    font-size: 3rem;
    @media only screen and (max-width: $sm) {
      display: none;
    }
  }
  &__linearTimer {
    display:block;
    @media only screen and (max-width: $sm) {
      display: none;
    }
  }
  &__circularTimer {
    display: none;
    @media only screen and (max-width: $sm) {
      display: block;
    }
  }

  &__waitingText {
    font-family: "CaustenM" !important;
    font-size: 22px;
    text-align: center;
    width: 80%;
  
    @media screen and (max-width: $md) {
      font-size: 15px;
      margin-top: 1.75rem;
      padding-bottom: 100px;
    }
  
    @media screen and (min-width: $md) {
      margin-top: clamp(0.5rem, 4vh, 2rem);
      margin-bottom: clamp(0.5rem, 4vh, 2rem);
      font-size: clamp(1rem, 3vmin, 3rem);
    }
  
    @media screen and (max-width: $md) and (min-height: $md) {
      font-size: 30px;
    }
  }
}
