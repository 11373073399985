@import "../../styles/abstracts/variables.scss";

.lbd {
    &__container {
        display: flex;
        flex-direction: column;
        height: 100%;
        font-family: "CaustenL";
        // width: 90%;
        // padding: 1rem;
    }
    &__header {
        display: flex;
        padding: 1rem;
        border-bottom: 1px solid white;
    }

    &__rank {
        flex: 20%;
    }
    
    &__pts {
        flex: 20%;
        margin-left: auto;
        text-align: right;
    }
    
    &__player {
        flex: 60%;
    }
    
    &__title {
        // padding-bottom: 1rem;
        font-size: 12px;
        line-height: 18px;
        
        @media only screen and (min-width: $md) {
            font-size: 14px;
            line-height: 14px;
        }
    }
    
    &__stats {
        display: flex;
        align-items: center;
        // padding: 10px 0;
        padding: 0.5rem 1rem;
    }
    
    &__playerAvatarName {
        display: flex;
        align-items: center;
        
        & > img {
            height: 40px;
            width: 40px;
            margin-right: 10px;
            @media only screen and (min-width: $md) {
                height: 40px;
                width: 40px;
            }
        }
    }
    
    &__playerYou {
        display: flex;
        align-items: center;
        // padding: 10px 0;
        padding: 0.5rem 1rem;
    }
    
    &__playerYou__container {
        background-color: #007fb3;
        @media only screen and (max-width: $md) {
            background-color: #0097D4;
        }
    }
    
    &__content {
        font-size: 15px;
        line-height: 18px;
        height: 80%;
        overflow-y: auto;
        padding-bottom: 10vh;
        margin-bottom: 20%;
        font-family: "CaustenL" !important;
        // display: flex;
        // flex-direction: column;
        // height: 100%;
        // letter-spacing: 0.07px;
        & p {
            font-family: "CaustenL";
        }
        @media only screen and (min-width: $md) {
            font-size: 18px;
        }
    }
    &__playerRole {
        font-size: 12px;
        line-height: 12px;
        margin-top: 3px;
        @media only screen and (min-width: $md) {
            font-size: 14px;
            line-height: 14px;
        }
    }

    &__winner{
        background-image: url(/images/winner_bg.png);
        background-origin: border-box;
        background-repeat: repeat;
    }
}
