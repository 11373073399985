@import "./../../../styles/abstracts/variables";

.qtestanswer {
    display: flex;
    flex-direction: column;
    background-color: $qq-blue;
    align-items: center;
    overflow-x: hidden;
    padding-top: 6.5rem;
    width: 100%;

    // @media screen and (min-width: $md) {
    //     padding-top: 5.25rem; 
    // }

    &__title {
        color: #fff;
        font-family: "CaustenEB" !important;
        font-size: 2.5rem;
        text-align: center;
    
        @media screen and (max-width: $md) {
            padding: 0 50px;
            font-size: 32px;
        }
        @media screen and (min-width: $md) {
            font-size: clamp(1rem, 5vh, 3.5rem);
        }
        @media screen and (max-width: $sm) {
            font-size: 1.75rem;
        }
    }
    
    &__titleText {
        color: #fff;
        font-family: "CaustenEB" !important;
        @media screen and (max-width: $md) {
            line-height: 1;
        }
        @media screen and (max-width: $xs) {
            font-size: smaller;
        }
        
    }

    &__image {
        // margin-top: 30px;
        // @media screen and (max-width: $sm) {
        //     margin-top: 15px;
        // }
        display: flex;
        flex-direction: column;
        min-height: 30vh;

        img {
            height: 300px;
            width: auto;
            object-fit: contain;
            border-radius: 5%;

            @media screen and (max-width: $md) {
                max-height: 200px;
                max-width: 300px;
            }

            @media screen and (min-width: $md) {
                height: 35vh;
            }

            @media screen and (max-width: $sm) {
                max-height: 140px;
                max-width: 210px;
            }
        }
    }

    &__info {
        // margin-top: 32px;
        margin-bottom: 20px;
        min-height: 50px;
        color: #fff;
        font-family: "CaustenM" !important;
        font-size: 22px;
        text-align: center; 
        width: 80%;

        @media screen and (max-width: $md) {
            font-size: 15px;
            // margin-top: 1.75rem;
            padding-bottom: 100px;
        
        }
        @media screen and (min-width: $md) {
            // margin-top: clamp(0.5rem, 4vh, 2rem);
            // margin-bottom: clamp(0.5rem, 4vh, 2rem);        
            font-size: clamp(1rem, 3vmin, 3rem);
        }

        @media screen and (max-width: $md) and (min-height: $md) {
            font-size: 30px;        
        }
    }

    &__infoText {
        color: #fff;
        font-family: "CaustenL" !important;
        white-space: pre-line;
    }

    &__curScoreText {
        color: #fff;
        font-family: "CaustenL" !important;
        white-space: pre-line;

        @media screen and (min-width: $md) {
            margin-bottom: 15px;
        }
        @media screen and (max-width: $md) {
            margin-bottom: 10px;
        }
        @media screen and (max-width: $sm) {
            font-size: small;
        }
    }

    &__connect {
        text-align: center;
    }

    &__scoreBanner {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100vh;
        font-family: "CaustenEB" !important;
        font-size: 1.5rem;
        color: white;
        border-radius: 1rem;
        background-color: $qq-dark-blue;
        padding: 0.5rem 1rem;

        @media screen and (min-width: $md) {
            margin-bottom: 15px;
        }
        @media screen and (max-width: $md) {
            margin-bottom: 5px;
            border-radius: 0.5rem;
        }
        @media screen and (max-width: $sm) {
            font-size: small;
            padding: 0.5rem 1rem;
            margin-bottom: 10px;
        }
    }

    &__resultTopImage {
        margin-top: 5%;
        margin-bottom: 2rem;

        @media screen and (max-width: $sm) {
            // display: none;
            margin-top: 20%;
            width: 450px;
            margin-bottom: 0rem;
        }
    }

    &__resultTitle {
        margin-bottom: 2rem;

        @media screen and (max-width: $sm) {
            margin-top: 10%;
        }
    }
    
    &__resultBanner {
        width: 100vh;
        font-size: 1.5rem;
        color: white;
        border-radius: 1rem;
        background-color: $qq-dark-blue;
        padding: 0.5rem 1rem;

        @media screen and (max-width: $md) {
            border-radius: 0.5rem;
        }
        
        // @media screen and (max-width: $sm) {
        //     font-size: small;
        //     padding: 0.5rem 1rem;
        //     margin-bottom: 2rem;
        // }
    }

    &__resultBottomImage {
        width: 300px;
        @media screen and (min-width: $sm) {
            display: none;
        }
    }

    &__scoreIcon{
        font-size: x-large;
        margin-right: 0.5rem;
        @media screen and (max-width: $sm) {
            font-size: medium;
            margin-right: 0.25rem;
        }
    }
}
