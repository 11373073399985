@import "../../styles/abstracts/_variables.scss";

.playQuiz {
  &__modal {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    width: 100%;

    & > h1 {
      font-size: 4.375rem;
      font-family: "CaustenEB";
      letter-spacing: 0.015rem;
      line-height: 4.375rem;
      text-align: center;

      @media only screen and (max-width: $sm) {
        font-size: 2rem;
        line-height: 2rem;
      }
      @media only screen and (max-width: $xl) {
        font-size: 2.5rem;
        line-height: 2.5rem;
      }
    }
    & > h2 {
      font-size: 2.375rem;
      font-family: "CaustenB";
      letter-spacing: 0.015rem;
      line-height: 4.375rem;
      text-align: center;

      @media only screen and (max-width: $md) {
        font-size: 1.75rem;
        line-height: 2rem;
      }
      & > span {
        color: $qq-green-3;
        letter-spacing: 1rem;
        font-size: 4.375rem;
        font-family: "CaustenEB";
        @media only screen and (max-width: $md) {
          font-size: 2rem;
        }
      }
    }
  }

  &__hostControl {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    &__row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 1.5rem;
    }
  }
}
