@import "../../../styles/abstracts/_variables.scss";

.createquiz{
    overflow: hidden;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    padding-top: 7vh;

    &__main{        
        display: flex;
        flex-grow : 1;
        flex-direction: column;
        align-items: center;
        padding-top: inherit;

        &__toolbar{
            display: flex;
            width: 96%;
            justify-content: flex-end;
            margin-top: -2rem;
            margin-bottom: 10px;
            margin-right: 15px;
            cursor: pointer;
            visibility: hidden;
        }
    }
}
// .createquiz{
//     overflow-x: hidden;
//     height: 100vh;
//     width: 100%;
//     display: flex;
//     flex-direction: row;

//     &__main{        
//         padding-top: 17vh;
//         // width: 100%;
//         display: flex;
//         flex-grow : 1;
//     }
    
// }

.page{
    &__title{
        font-size: 2.5rem;
        color: white;
        font-family: 'CaustenR';
        font-style: normal;
        font-weight: 800;
        line-height: 50px;
        align-self: flex-start;
        margin-bottom: 1rem;
    }
}