.customModal {
  display: flex;
  flex-direction: column;
  &__cross {
    cursor: pointer;
    height: 1.6rem;
    width: 1.6rem;
    margin-left: auto;
  }
  @media screen and (max-width: 820px) {
    width: 80%;
  }
}
