@import "./../../../styles/abstracts/variables";

.scheduleQtest__modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  & > h1 {
    font-size: 3.3rem;
    font-family: "CaustenEB";
    text-align: center;

    @media only screen and (max-width: $md) {
      font-size: 2.5rem;
    }
    @media only screen and (max-width: $sm) {
      font-size: 1.75rem;
    }
  }

  & > p {
    color: #FAA0A0;
    font-family: "CaustenM";
    text-align: center;
    font-size: 1rem;
    font-weight: 300;
    max-width: 30rem;
    @media only screen and (max-width: $sm) {
      font-size: 0.8rem;
    }
  }
}

.scheduleQtest__btn {
  height: 3rem;
  width: 12rem;
  background-color: #fdb913;
  box-shadow: none !important;
  border-radius: 10px !important;
  font-family: "CaustenB" !important;
  font-size: 1.2rem !important;
  letter-spacing: 1px !important;
  margin: auto;

  @media only screen and (max-width: $sm) {
    width: 10rem;
    font-size: 1rem !important;
  }
}

.scheduleQtest__dateTimeTextField {
  color: #ffffff;
}

.scheduleQtest__radioBtn {
  display: flex;
  flex-direction: row;
  font-size: clamp(0.9375rem, 2vw, 1.5625rem);
  gap: 1rem;

  @media only screen and (max-width: $sm) {
    flex-direction: column;
  }

  &__showAnswerBtn {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__showScoreBtn {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

.scheduleQtest__details {
  // display: flex;
  // flex-direction: column;
  // width: 85%;

  // @media screen and (max-width: $md) {
  //   width: 90%;
  // }
  // @media screen and (max-width: $sm) {
  //   width: 100%;
  // }

  // & > label {
  //   font-family: "CaustenR";
  //   font-size: 1.5625rem;
  //   font-size: clamp(0.9375rem, 2vw, 1.5625rem);
  //   letter-spacing: 0.0462rem;
  //   line-height: clamp(0.9375rem, 2vw, 1.5625rem);
  //   line-height: 1.5625rem;
  //   margin-bottom: 0.625rem;
  //   @media screen and (max-width: $sm) {
  //     // font-size: 0.9375rem;
  //     // font-weight: 700;
  //     // line-height: 0.9375rem;
  //   }
  // }
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: clamp(0.4rem, 1rem, 1.5rem);
  width: 85%;

  @media screen and (max-width: $md) {
    width: 90%;
    flex-direction: column;
  }
  @media screen and (max-width: $sm) {
    width: 100%;
  }

  & > label {
    font-family: "CaustenR";
    font-size: clamp(0.9375rem, 2vw, 1.5625rem);
    letter-spacing: 0.0462rem;
    line-height: clamp(0.9375rem, 2vw, 1.5625rem);
    line-height: 1.5625rem;
    text-align: center;
  }
}

// .marginTop {
//   margin-top: 2rem;
//   @media screen and (max-width: $md) {
//     margin-top: 1rem;
//   }
// }

.hostControl{
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: clamp(0.4rem, 1rem, 1.5rem);
  width: 85%;

  @media screen and (max-width: $sm) {
    flex-direction: column;
  }

  &__label {
    font-family: "CaustenR";
    font-size: clamp(0.9375rem, 2vw, 1.5625rem);
    letter-spacing: 0.0462rem;
    line-height: clamp(0.9375rem, 2vw, 1.5625rem);
    line-height: 1.5625rem;
    text-align: center;
  }
}

.hostControl > * {
    flex: 1 0 auto;
}
