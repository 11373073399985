@import "./../../../styles/abstracts/variables";

.qtestQuestionType {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 87%; // To correct options getting hidden below footer
    overflow-y: auto;
    // height: -webkit-fill-available; // Added by sani
    @media only screen and (min-width: $md) {
        //for firefox
        scrollbar-color: #007fb3 #30b2e7;
    }
}

.qtestQuestionType::-webkit-scrollbar {
    @media only screen and (min-width: $md) {
        // display: none;
        width: 7px;
    }
}

.qtestQuestionType::-webkit-scrollbar-track {
    @media only screen and (min-width: $md) {
        background-color: rgba($color: #000000, $alpha: 0.0);
    }
}

/* Handle */
.qtestQuestionType::-webkit-scrollbar-thumb {
    @media only screen and (min-width: $md) {
        background: #007fb3;
        border-radius: 7px;
    }
}

.modal_lockedAnswer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    & > h1 {
        font-size: 4.3rem;
        font-family: "CaustenEB";
        text-align: center;

        @media only screen and (max-width: 1200px) {
            font-size: 3rem;
        }
        @media only screen and (max-width: 500px) {
            font-size: 2rem;
        }
    }

    &__bottom {
        display: flex;
        align-items: center;
        justify-content: center;
        @media only screen and (max-width: 500px) {
            flex-direction: column;
        }
        
        & > p {
            margin-left: 2rem;
            font-family: "CaustenM";
            text-align: center;
            font-size: 1.25rem;
            font-weight: 300;
            max-width: 30rem;
            line-height: 30px;
            @media only screen and (max-width: 500px) {
                margin: 0;
                margin-top: 1rem;
            }
        }
    }
}