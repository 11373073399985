@import "../../../styles/abstracts/_variables.scss";

.searchfilter{
    display: flex;
    width: 100%;
    flex-direction: column;
    color: white;  
    margin-top: 1.25rem;
    @media screen and (max-width:1200px) {
        margin-bottom: 1rem; 
    }
    
    &__content{
        padding-left: 14%;
        padding-right: 14%;

        &__input{
            & > input{
                height: 2.15rem;
                width: 13rem;
            }
        }

        &__label{
            font-family: "CaustenR";
            font-size:  clamp(1rem, 3vw ,1.375rem);               
            font-weight: 700;
            letter-spacing: 0.0313rem;
        }

        &__item{
            display: flex;
            flex-direction: row;
            justify-content: flex-start; 
            align-items: baseline;
            gap: 1rem; 
            @media screen and (max-width:1200px) {
                justify-content: center;
            }
        }
    }  
}