@import "../../styles/abstracts/_variables.scss";

.home {
  display: flex;
  background-color: #30b2e7;
  overflow-x: hidden;
  overflow-y: hidden;
  height: 100vh; // changed by sani
  width: 100%;

  @media screen and (max-width: $md) {
    position: fixed;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-y: unset;
  }

  &__all-set-to-quiz-today {
      margin-left: 4.375rem;
      margin-top: 15vh;
      color: #fff;
      font-family: "CaustenEB";
      font-size: clamp(4rem, 7.64vw, 6.875rem);
      line-height: clamp(4rem, 7.64vw, 6.875rem);

    @media screen and (max-width: $md) {
      height: 30%;
      margin: unset;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    
    @media screen and (min-width: $sm) and (max-width: $md) {
      font-size: 5rem;
      text-align: center;  
    }
    
    @media screen and (max-width: $xs) {
      font-size: 2.2rem;
      line-height: 2.2rem;
    }
  }
  
  &__mobile_qtImage{
    @media screen and (max-width: $md) {
      height: 50%; //for chrome
      height: 45dvh; //for safari and mozzila that support it
    }
    
  }

  &__left {
    flex: 0.55;
    // padding-top: 2rem;
    @media screen and (max-width: $md) {
      height: 80%;
      flex: unset;
      padding: unset;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    }
  }

  &__right {
    flex: 0.45;
    height: 100%;
    justify-content: center;
    align-items: center;
    // padding-top: 6rem;
    & > img{
      margin-left: 3vw;
      width: 100%;
      height: 100%;
    }
  }

  &__playButtons {
    margin-top: 65px;
    margin-left: 70px;
    
    @media screen and (max-width: $md) {
      padding: 1rem;
      margin: unset;
    }
  }

  &__modal {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    width: 100%;

    & > h1 {
      font-size: 4.375rem;
      font-family: "CaustenEB";
      letter-spacing: 0.015rem;
      line-height: 4.375rem;
      text-align: center;

      @media only screen and (max-width: $sm) {
        font-size: 2rem;
        line-height: 2rem;
        text-align: center;
      }
    }

    & > p {
      font-family: "CaustenM";
      text-align: center;
      font-size: 1.25rem;
      line-height: 1.875rem;
      letter-spacing: 0.0206rem; 
      max-width: 90%;
      @media only screen and (max-width: $sm) {
        font-size: 0.9375rem;
        line-height: 1.375rem;
        text-align: center;
      }
    }
  }

  &__modalButtonContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }

  &__modalButton {
    height: 4.6875rem;
    width: 14.75rem;
    background-color: #30b2e7;
    box-shadow: none !important;
    border-radius: 10px !important;
    font-family: "CaustenR" !important;
    font-size: 1.75rem !important;
    font-weight: 700 !important; 
    letter-spacing: 0.0512rem !important;
    line-height: 1.75rem !important;

    @media only screen and (max-width: $sm) {
      height: 3rem;
      width: 9.375rem;
      font-size: 1rem !important;
    }
  }

  &__dividerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    width: 93%;

    @media only screen and (max-width: 500px) {
      width: 100%;
    }

    & > p {
      text-align: center;
    }
  }

  &__modalJoin {
    display: flex;
    gap: 1rem;
    width: 93%;
    justify-content: center;

    @media only screen and (max-width: 500px) {
      flex-direction: column;
      align-items: center;
      width: 100%;
    }

    & > input {
      border-radius: 10px;
      background-color: transparent;
      border: 2px solid white;
      outline: none;
      flex-grow: 1;
      color: white;
      font-family: "CaustenL";
      letter-spacing: 0.8px;
      font-size: 1.5rem;
      font-weight: 300;
      text-align: center;

      @media only screen and (max-width: $md) {
        font-size: 1.25rem;
      }
      @media only screen and (max-width: $sm) {
        font-size: 1rem;
      }
      
      @media only screen and (max-width: 500px) {
        width: 90%;
        height: 3rem;
      }

      &::placeholder {
        text-align: center;
        color: white;
      }

      &:-ms-input-placeholder {
        text-align: center;
        color: white;
      }
    }
  }
}