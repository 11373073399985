@import "../../styles/abstracts/variables.scss";

.titleComponent{
    position: relative;
    display: flex;
    width: 100%;
    padding-top: 7rem;
    justify-content: center;
    align-items: center;
    // @media screen and (max-width:$sm) {
    //     margin-top: 6rem;
    // }
    &__backArrow{
        position: absolute;
        cursor: pointer;
        left: 5%; 
        @media screen and (max-width:$sm) {
            height: 1.25rem;
            object-fit: contain;            
        }      
    }
    &__icon {
        display: flex;
        align-items: center;
        position: absolute;
        right: 5%;
        cursor: pointer;
        color: white;
        border: 1px solid white;
        padding-left: 1rem;
        padding-right: 1rem;
        border-radius: 8px;
        gap: 0.5rem;
        height: 70%;
        @media only screen and (max-width: $sm) {
            border: none;
            margin-left: 20%;
            margin-left: 55%;
         }
      }
}