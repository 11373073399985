@import "../../../src/styles/abstracts/variables";

.slider {
  &__container {
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__content {
    display: flex;
    transition: transform 0.5s ease;
    width: 100%;
  }

  &__navigation {
    position: absolute;
    top: 60%;
    width: 95%;
    display: flex;
    justify-content: space-between;
    transform: translateY(-50%);
    pointer-events: none; 
    @media only screen and (max-width: $md) {
      top: 65%;
    }
  }

  &__arrow {
    cursor: pointer;
    padding: 0.5rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: all; 
  }

  &__slide {
    min-width: 100%;
    box-sizing: border-box;
    text-align: center;

    p {
      margin-bottom: 2rem;
      font-size: 1.5rem;
      @media only screen and (max-width: $md) {
        font-size: 1rem;
      }
    }

    img {
      max-width: 100%;
      height: 37vh;
      border: 1px solid black;
      border-radius: 10px;
      @media only screen and (max-width: $md) {
        height: 17vh;
        width: 30vh;
      }
    }
    &__roomCode {
      color: green;
      letter-spacing: 0.5rem;
      font-family: "CaustenEB";
      @media only screen and (max-width: $md) {
        letter-spacing: 0.25rem;
      }
    }
  }
}