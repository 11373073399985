@import "../../styles/abstracts/variables";

.InputComponent{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    
    & > label{
        font-family: "CaustenR";
        font-size:  clamp(1rem, 3vw ,1.375rem);               
        font-weight: 700;
        letter-spacing: 0.0313rem;
        line-height: 1.375rem;
        margin-bottom: 1rem;
    }

    & > input {
        font-family: "CaustenM";
        font-size:  clamp(1rem, 3vw ,1.375rem);               
        letter-spacing: 0.0313rem;
        line-height: 1.375rem;
        height: 2.8125rem;
        border-radius: 10px;
        border: 1px solid #FFF;
        background-color: transparent;
        color: #FFF ;
        outline: none;
        padding-left: 3%;
        padding-right: 10%;
        margin-bottom: 1rem;
        @media screen and (max-width:$md) {                    
            letter-spacing: 0.0313rem;
            line-height: 1.125rem;           
        }  
        
        &:disabled{
            border: none;
            cursor: not-allowed;
        }
    }
    //To remove the default eye icon from MS edge and IE
    input::-ms-reveal,
    input::-ms-clear {
        display: none;
    }  
    //To remove arrows when type is number
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }
}  