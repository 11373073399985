@import "../../../styles/abstracts/_variables.scss";

.createMCQ{
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    &__inner{
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        padding-left: 5rem;
        padding-right: 5rem;
        &__image{
            flex-basis: 0;
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            height: 100vh;
            margin-top: 1rem;
            margin-bottom: 1rem;
            text-align: center;
            position: relative;
            & > img {
                position: absolute;
                top: 58%;
                left: 50%;
                transform: translate(-50%, -50%);
                height: 90%;
                min-height: 90%;
            }
            & > video {
                position: absolute;
                top: 58%;
                left: 50%;
                transform: translate(-50%, -50%);
                height: 90%;
                min-height: 90%;
            }
            & > audio {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
        &__radioGroup {
            flex-wrap: nowrap !important;
        }
    }
}

.questionTitle{
    font-family: 'CaustenR';
    font-style: normal;
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.5rem;
    color: #1E1E1E;
    padding-bottom: 0.5rem;
}

.options{
    display: grid;
    grid-template-columns: auto auto;
    row-gap: 1rem;
    column-gap: 3rem;
    padding-bottom: 1rem;
    & > div{
        display: flex;
        flex-direction: row;
        gap: 1rem;
        position: relative;
        & > p{
            padding-bottom: 0;
            margin-top: 8px;
        }
        & > input{
            flex-basis: 0;
            flex-grow: 1;
            padding-right: 2.5rem;
        }
        & > label{
            position: absolute;
            right:-13px;
            top:50%;
            transform: translateY(-50%)
        }
    }
}
