@import "../../../styles/abstracts/_variables.scss";

.titleCard{
    display: flex;
    flex-direction: column;
    background: $qq-blue;
    width: 90%;
    height: 85%;
    margin-left: 5%;
    color: white;
    font-family: "CaustenR";
    font-weight: 800;
    font-size: 3.125rem;
    border-radius: 1.25rem;
    padding: 4rem 2rem;
    &__heading {        
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        position: relative;      
    }
    &__title {
        font-family: "CaustenR" !important;
        font-weight: 800;
        font-size: 3rem;
        text-align: center;
    }
    &__clickable{
        cursor: pointer;
        height: 2rem;
        position: absolute;
        top: 10px;
        left: 0px;
    }
}