@import "./../../styles/abstracts/variables";

.warning__modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    & > h1 {
        font-size: 4rem;
        font-family: "CaustenEB";
        text-align: center;

        @media only screen and (max-width: 1500px) {
            font-size: 3.3rem;
        }
        @media only screen and (max-width: 1200px) {
            font-size: 3rem;
        }
        @media only screen and (max-width: 500px) {
            font-size: 2rem;
        }
    }

    & > p {
        font-family: "CaustenM";
        text-align: center;
        font-size: 1.25rem;
        font-weight: 300;
        max-width: 30rem;
    }
}

.warning__button {
    height: 3rem;
    width: 12rem;
    background-color: $qq-blue !important;
    box-shadow: none !important;
    border-radius: 10px !important;
    font-family: "CaustenB" !important;
    font-size: 1.2rem !important;
    letter-spacing: 1px !important;
    margin: auto;
    margin-top: 1.5rem !important;

    &:hover{
        background-color: $qq-dark-blue !important;
    }

    @media only screen and (max-width: 500px) {
        width: 10rem;
        font-size: 1rem !important;
    }
}
