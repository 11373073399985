 .createMTF{
    &__answerTitle{
        margin-top: 3.375rem;
        margin-bottom: 1.8125rem;
    }

    &__answers{
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: center;
        align-items: center;
        margin-bottom: 1.3125rem;
        & > input {
            flex-basis: 0;
            flex-grow: 1;
        }

        & > p{
            width: 0.75rem;
            margin-right: 0.5rem;
        }

    }
}
