@import "../../../styles/abstracts/_variables.scss";

.nameAge {
  background-color: #75B543;
  min-height: 100vh;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  color: white;
  // justify-content: center;
  & > img {
    @media screen and (max-width:$sm) {
      height: 2rem;                
    }
  }

  & >  h1 {
      margin-top: 9rem;
      // font-size: 4.375rem;
      font-size: clamp(2rem , 3vw, 4.375rem);
      font-family: "CaustenEB";
      letter-spacing: 0.015rem;
      // line-height: 4.375rem;
      line-height: clamp(2rem , 3vw, 4.375rem);
      text-align: center;
      @media screen and (max-width:$sm) {
        // font-size: 2rem;
        // line-height: 2rem;
      }
  }

  &__errorMessage {
    color: $qq-red;
    font-family: "CaustenR";
    font-size: clamp(0.9rem, 2vw, 1.2rem);
    margin-top: 1rem;
  }

  &__details {
    display: flex;
    flex-direction: column;
    width: 70%;  

    @media screen and (max-width:$md) {
      width: 80%;
    }   
    & > select {
      background-color: transparent;
      height: 2.8rem;
      padding: 0.2rem 0.4rem;
      margin-top: 0.8rem;
      border-radius: 8px;
      outline: none;
      border: 2px solid white;
      color: white;
      font-family: "CaustenR";
      font-size: 1.2rem;
    }
    & > label {
      font-family: "CaustenR";
      font-size: 1.5625rem;
      font-size: clamp(0.9375rem, 2vw, 1.5625rem);
      letter-spacing: 0.0462rem;
      line-height: clamp(0.9375rem , 2vw, 1.5625rem);
      line-height: 1.5625rem;
      @media screen and (max-width:$sm) {
        // font-size: 0.9375rem;
        font-weight: 700;
        // line-height: 0.9375rem;          
      }
    }
    & > input {
      background-color: transparent;
      margin-top: 0.8rem;
      border-radius: 8px;
      outline: none;
      border: 2px solid white;
      height: 2.8rem;
      color: white;
      padding: 0.2rem 0.5rem;
      font-family: "CaustenR";
      font-size: 1.2rem;
    }      
  }

  &__avatar{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-top: 1rem;
    overflow-x: auto;    
    & > img {
      height: 5rem;
      width: 5rem;
      margin: 5px;
      margin-right: min(0.5rem);     
      @media only screen and (max-width: $md) {
        height: 4rem;
        width: 4rem;
      }      
      @media only screen and (max-width: $sm) {
        height: 3rem;
        width: 3rem;
      }
    }

    &__selected{
      border: 5px solid $qq-yellow;
      border-radius: 1rem;
      @media only screen and (max-width: $md) {
        border: 3px solid $qq-yellow;
        border-radius: 0.5rem;
      }    
    }

    &::-webkit-scrollbar {
      height: 8px;
    }
    &::-webkit-scrollbar-track {
      background-color: $qq-green;
      height:8px;
    }
    &::-webkit-scrollbar-thumb {
      background: $qq-dark-green; 
      border-radius: 100px;
    }
  }

  &__button {
      margin-top: auto;
      @media screen and (max-width:$md) {
        margin-top: unset;
        margin-bottom: 3rem;
      }
      color: white !important;      
  }
  
}

.marginTop{
  margin-top:  2rem; 
  @media screen and (max-width:$md) {
    margin-top: 1rem;
  }   
}