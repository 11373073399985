@import "./../../../../styles/abstracts/variables";

.quizDragArrange {
    height: 100%;
    width: 65%;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (max-width: $md) {
        width: 80%;
        height: 100%;
        padding-top: 12rem;
        // padding: unset;
        justify-content: space-between;
        padding-bottom: 2rem;
    }

    @media only screen and (min-width: $md) {
        padding-top: clamp(4rem, 11vh, 6.2rem); //clamp(2.5rem, 2vh, 5rem);
    }
    margin: 0 auto;

    &__question {
        @media screen and (max-width: $md) {
            max-height: 12rem !important;
        }
    }

    &__submitButton {
        padding-top: 1rem;
    }

    &__window {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &__container {
        display: flex;
        justify-content: center;
        align-items: center;

        @media screen and (max-width: $md) {
            padding-top: 1rem;
        }

        &__board {
            width: 100%;
            display: flex;
            justify-content: space-between;
            overflow: auto;
            flex-grow: 1;
            padding: 2rem 0;
            // align-items: center;
            // overflow: hidden;
    
            @media screen and (max-width: $md) {
                flex-direction: column;
                align-items: center;
                justify-content: space-evenly;
                padding-top: 0;
            }
            @media screen and (min-width: $md) {
                min-width: 50rem;
            }
        }

        @media screen and (min-width: $md) {
            gap: 10rem;
        }
        @media screen and (max-width: $md) {
            flex-direction: column; 
            justify-content: center;
            align-items: center;
        
            &__answerCount {
              order: 1;
            }
        
            &__skipBtn {
              order: 2;
              padding-top: 1rem;
            }
        
            &__board {
              order: 3;
            }
        }
    }
}
.quizDragArrange__board_column {
    width: 45%;
    & > h3 {
        color: white;
    }
    
    @media screen and (max-width: $md) {
        margin-top: 2rem;
        width: 100%;
        // overflow-y: auto;
    }
}

.quizDragArrange__droppable_column {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1rem 1.5rem 1.5rem 0;
    // min-height: 60%;
    height: 100%;
    // background-color: aqua;
    border: 2px dashed white;
    border-radius: 0.5rem;
    overflow-y: auto;

    @media screen and (max-width: $md) {
        min-height: 4rem;
        padding: 0.5rem 1rem 1rem 0;
    }
}

.quizDragArrange__droppable_column_message{
    // flex-direction: unset;
    // // align-items: center;
    // justify-content: center;
    text-align: center;
    color: gray;
    // opacity: 50%;
    transition: none;

    @media screen and (min-width: $md) {
        padding-top: 2rem;
    }
}

.quizDragArrange__item_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > span {
        padding: 1rem;
        color: white;
        @media screen and (max-width: $md) {
            padding: 0.5rem 1rem 0rem 1rem;
        }
    }

}
.quizDragArrange__item {
    text-align: left;
    width: 100%;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 6px;
    background-color: transparent;
    color: #fff;
    border: 2px solid #fff;
    border-radius: 10px;
    font-family: "CaustenM";
    font-size: 28px;

    letter-spacing: 0.1px;

    margin-top: 10px;

    &:hover {
        background-color: #fff;
        color: #30b2e7;
    }

    @media only screen and (min-width: $md) {
        font-size: clamp(1rem, 3vmin, 3rem);
    }

    @media only screen and (max-width: $md) {
        font-size: 15px;
        letter-spacing: 0.05px;
        padding: unset;
        padding-left: 0.5rem;
    }
    @media only screen and (max-width: $md) and (min-height: $md) {
        font-size: 30px;
        letter-spacing: 0.05px;
    }
    @media only screen and (min-width: $md) and (max-height: $md) {
        padding-top: 6px;
        padding-bottom: 6px;
    }
    @media only screen and (min-width: $md) and (max-height: $sm) {
        padding-top: 0;
        padding-bottom: 0;
    }
}

.quizDragArrange__item_answer {
    background-color: $qq-yellow;
    color: white;
    font-family: "CaustenM";
    border: none;
}

.quizDragArrange__leaderboardOpen {
    width: 80%;
}
