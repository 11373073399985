@import "../../../styles/abstracts/_variables.scss";

.quizDrawerComponent {
  &__header {
    width: 90%;
    margin: 0 auto;
    padding-bottom: 1.5rem;
    color: white;
    border-bottom: 1px solid white;
    &__edit {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      &__icon {
        margin: 0.5rem;
        cursor: pointer;
      }
    }
    &__title {
      font-size: 1.5rem;
      font-weight: 600;
      color: white;
      margin: 0;
    }
    &__bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 25px;
    }
    &__date {
      font-size: 0.8rem;
      flex-shrink: 0;
    }
    &__button {
      color: white;
      border-color: #FDB913;
      padding: 0.4rem 0.4rem;
      font-size: x-small;
      font-family: CaustenR;
      font-weight: 700;
      letter-spacing: 1px;
      border-radius: 7px;
      background-color: $qq-green;
      border: 0;
    }
  }

  &__questionsList {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    padding: 1rem 0.9rem 1rem 1rem;
    overflow-y: auto;
    // background-color: $qq-green;

    &::-webkit-scrollbar{
        width: 7px;
    }
    &::-webkit-scrollbar-thumb{
        background-color: $qq-yellow;
        border-radius: 3.5px;
    }
    &::-webkit-scrollbar-track{
        background-color: $qq-dark-blue;
        border-radius: 7px;
    }
    
    &__question {
      display: flex;
      flex-direction: row;
      width: 100%;
      &__index {
        color: white;
        font-size: 1.5rem;
        font-weight: 600;
        margin: 0.75rem 0.5rem 0.5rem 0;
      }
    }
  }
}
