@import "../../styles/abstracts/_variables.scss";

.selectMultiplayer {
  height: 100vh; 
  overflow-x: hidden;
  display: flex;
  flex-direction: column;

  &__header {
    height: 50%;
    color: #fff;
    font-family: "CaustenEB";
    font-size: 2rem; 
    text-align: center;
    background: #75b543;
    position: relative;

    @media only screen and (max-width: $md) {
      height: 30%;
    }

    & > h1 {
      padding-top: 161px;
      font-size: 110px;
      line-height: 110px;
      font-family: "CaustenEB";

      @media only screen and (max-width: $xl) {
        padding-top: 9rem;
        font-size: 6rem;
        line-height: 6rem;
      }

      @media only screen and (max-width: $lg) {
        padding-top: 8rem;
        font-size: 5rem;
        line-height: 5rem;
      }

      @media only screen and (max-width: $md) {
        padding-top: 102px;
        font-size: 32px;
        line-height: 38px;
      }

      @media only screen and (min-width: $lg) and (max-height: $md) {
        padding-top: 6rem;
        font-size: 5rem;
        line-height: 5rem;
      }
    }
  }

  &__buttonContainer {
    height: 50%;
    display: flex;

    @media only screen and (max-width: $md) {
      // Added by sani
      flex-direction: column;
      height: 70%;
    }
  }

  &__playWithFriendsButton {
    color: #fff !important;

    background-color: #4b9710;
    border-radius: 0;

    cursor: pointer;
    margin-right: -8rem;
    flex: 6;
    position: relative;
    overflow-y: hidden;
    text-align: center;
    overflow: hidden;

    @media only screen and (max-width: $md) {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0;
    }

    & > img {
      position: absolute;
      bottom: -6.7px;
      left: -29px;
      overflow-y: hidden;
      width: 100%;
      z-index: 9;

      @media only screen and (max-width: $xl) {
        width: 95%;
        position: absolute;
        bottom: -5px;
        left: 45%;
        transform: translateX(-50%);
      }

      @media only screen and (max-width: $lg) {
        width: 100%;
        left: 50%;
      }

      @media only screen and (max-width: $md) {
        width: 55%;
      }

      @media only screen and (max-width: $sm) {
        width: 85%;
      }

      @media only screen and (max-width: $xs) {
        width: 100%;
        height: auto;
        position: absolute;
        bottom: -5px;
        left: 50%;
        transform: translateX(-50%);
      }

      @media only screen and (min-width: $md) and (max-height: $md) {
        width: 55%;
      }
      @media only screen and (min-width: $md) and (max-height: $md) and (min-height: $sm) {
        height: 55%;
        width: auto;
      }
    }
  }

  &__playWithStrangersButton {
    flex: 6;
    position: relative;
    color: #fff !important;
    font-family: "CaustenR" !important;
    font-size: 2.125rem !important;
    font-weight: 700 !important;
    letter-spacing: 1px;
    line-height: 1.6;
    text-align: center;
    background-color: gray;
    border-radius: 0;
    justify-content: center;
    align-items: center;
    overflow-y: hidden;
    cursor: not-allowed;
    z-index: 10;
    clip-path: polygon(12% 0, 100% 0, 100% 100%, 0% 100%);

    @media only screen and (max-width: $md) {
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    }

    & > img {
      position: absolute;
      right: 140px;
      bottom: -9px;
      width: 380px;
      z-index: 9;

      @media only screen and (max-width: $xl) {
        width: 44%;
        height: auto;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
      }

      @media only screen and (max-width: $lg) {
        width: 45%;
      }

      @media only screen and (max-width: $md) {
        width: 25%;
      }

      @media only screen and (max-width: $sm) {
        width: 38%;
      }

      @media only screen and (max-width: $xs) {
        width: 45%;
      }

      @media only screen and (min-width: $xl) {
        width: 50%;
      }
      @media only screen and (min-width: $md) and (max-height: $md) {
        width: 25%;
      }
      @media only screen and (min-width: $md) and (max-height: $md) and (min-height: $sm) {
        height: 55%;
        width: auto;
      }
     

    }
  }

  .back__arrow {
    @media only screen and (max-width: $md) {
      height: 1.25rem;
      position: fixed;
      left: 5%;
      top: 15%;
    }
  }

  &__bottomBar {
    @media only screen and (max-width: $md) {
      height: 10%;
      background-color: $qq-yellow;
    }
  }

  &__buttonText {
    position: relative;
    z-index: 10;

    & > h3 {
      font-family: "CaustenB";
      padding-top: 58px;
      font-size: 34px;
      line-height: 34px;
      letter-spacing: 1px;

      @media only screen and (max-width: $lg) {
        font-size: 1.5rem;
        line-height: 1.5rem;
        padding-top: 3rem;
      }

      @media only screen and (max-width: $md) {
        font-size: 18px;
        line-height: 18px;
        padding-top: 26px;
      }
      @media only screen and (max-width: $md) and (min-height: $md) {
        font-size: 28px;
        line-height: 28px;
        padding-top: 26px;
      }
    }

    & > p {
      padding-top: 20px;
      font-size: 20px;
      font-family: "CaustenL";
      line-height: 30px;
      letter-spacing: 0.33px;
      font-weight: 200;

      @media only screen and (max-width: $lg) {
        font-size: 0.94rem;
        line-height: 1.37rem;
        padding-top: 0.5rem;
      }

      @media only screen and (max-width: $md) {
        font-size: 15px;
        line-height: 22px;
        padding-top: 10px;
      }

      @media only screen and (max-width: $md) and (min-height: $md) {
        font-size: 20px;
        line-height: 20px;
        padding-top: 18px;
      }
      
    }
  }

  &__modal {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    width: 100%;

    & > h1 {
      font-size: 4.375rem;
      font-family: "CaustenEB";
      letter-spacing: 0.015rem;
      line-height: 4.375rem;
      text-align: center;

      @media only screen and (max-width: $md) {
        font-size: 3rem;
        line-height: 3rem;
      }

      @media only screen and (max-width: $sm) {
        font-size: 2rem;
        line-height: 2rem;
      }
    }

    & > p {
      font-family: "CaustenM";
      text-align: center;
      font-size: 1.25rem;
      line-height: 1.875rem;
      letter-spacing: 0.0206rem; 
      max-width: 90%;
      @media only screen and (max-width: $sm) {
        font-size: 0.9375rem;
        line-height: 1.375rem;
        text-align: center;
      }
    }
  }

  &__modalButtonContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }

  &__modalButton {
    height: 4.6875rem;
    width: 14.75rem;
    background-color: #30b2e7;
    box-shadow: none !important;
    border-radius: 10px !important;
    font-family: "CaustenR" !important;
    font-size: 1.75rem !important;
    font-weight: 700 !important; 
    letter-spacing: 0.0512rem !important;
    line-height: 1.75rem !important;

    @media only screen and (max-width: $sm) {
      height: 3rem;
      // width: 9.375rem;
      width: unset;
      font-size: 1rem !important;
    }
  }

  &__dividerContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    width: 93%;

    @media only screen and (max-width: 500px) {
      width: 100%;
    }

    & > p {
      // flex: 0.1;
      text-align: center;
    }
  }

  &__modalJoin {
    display: flex;
    gap: 1rem;
    width: 93%;
    justify-content: center;

    @media only screen and (max-width: 500px) {
      flex-direction: column;
      align-items: center;
      width: 100%;
    }

    & > input {
      border-radius: 10px;
      background-color: transparent;
      border: 2px solid white;
      outline: none;
      flex-grow: 1;
      color: white;
      font-family: "CaustenL";
      letter-spacing: 0.8px;
      font-size: 1.5rem;
      font-weight: 300;
      text-align: center;

      @media only screen and (max-width: $md) {
        font-size: 1.25rem;
      }
      @media only screen and (max-width: $sm) {
        font-size: 1rem;
      }
      
      @media only screen and (max-width: 500px) {
        width: 90%;
        height: 3rem;
      }

      &::placeholder {
        text-align: center;
        color: white;
      }

      &:-ms-input-placeholder {
        text-align: center;
        color: white;
      }
    }
  }
}


