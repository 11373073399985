.leaderboard {
  margin-top: 3.5rem;
  color: white;
  // background-color: #0097d4;
  // width: 328px;
  height: 100%;
  overflow-y: hidden;
  &__header {
    margin-top: 1rem;
    padding: 1rem;
    background-color: #007fb3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    & > p {
      font-family: "CaustenR";
      font-size: 1.12rem;
      & > span {
        font-family: "CaustenR";
        font-size: 0.8rem;
      }
    }
    & > img {
      height: 1rem;
      width: 1rem;
    }
  }
  &__title{
    font-family: "CaustenM";
    font-size: 14px;
    line-height: 14px;

    // @media only screen and (min-height: 900px) {
    //     font-size: 1.5rem;
    // }
  }
  &__element{
    font-family: "CaustenM";
    font-size: 20px;
    line-height: 18px;

    // @media only screen and (min-height: 900px) {
    //     font-size: 1.8rem;
    // }
  }

  &__playerScoreHead {
    padding: 0 1rem;
  }
  &__playerScoreHeaderTitle {
    padding: 1rem 0;
    font-family: "CaustenM";
    font-size: 0.7rem;
    display: flex;
    // grid-template-columns: 1fr 6fr 1fr;
    align-items: center;
    border-bottom: 1px solid white;
  }

  &__players {
    padding: 1rem;
    font-family: "CaustenM";
  }
  &__playerInfo {
    display: flex;
    // grid-template-columns: 1fr 6fr 1fr;
    align-items: center;
    margin-top: 0.8rem;
  }
  &__playerAvatarName {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    & > p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100px;
    }
    & > img {
      height: 2rem;
      width: 2rem;
    }
  }
  &__noScoreText {
    padding: 1rem;
    font-family: "CaustenM";
  }
}
