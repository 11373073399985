@import "./../../../styles/abstracts/variables";

.questionStatsComponent {
  padding: 6rem 6rem 0rem 6rem;
  text-align: center;
  color: white;
  font-size: 2rem;
  & > h1 {
    padding-bottom: 2rem;
  }
  @media screen and (max-width: $md) {
    & > h1 {
      font-size: xx-large;
    }
    height: 100dvh;
    padding: 12rem 2rem 2rem 2rem;
  }

  &__container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding-top: 1rem;
    padding-bottom: 1.5rem;
    @media screen and (max-width: $md) {
      flex-direction: column;
    }
    &__graph {
      width: 50%;
      @media screen and (max-width: $md) {
        width: 100%;
        height: 15rem;
      }
    }
    &__data {
      width: 40%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 2rem;
      @media screen and (max-width: $md) {
        width: 100%;
        flex-direction: column;
        padding: 1rem 0 5rem 0;
      }

      &__attemptedText {
        font-size: x-large;
      }
    }
  }

  &__nextBtnFooter {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 899px) {
        position: fixed;
        flex-direction: row;
        bottom: 0;
        height: 100px;
        width: 100%;
        background-color: #007fb3;
        padding: 0 1rem 0 1rem;
    }
  }
}
