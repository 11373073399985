@import "./../../../styles/abstracts/variables";

.quizanswer {
    display: flex;
    flex-direction: column;
    background-color: $qq-blue;
    align-items: center;
    overflow-x: hidden;
    padding-top: 9.5rem;

    @media screen and (min-width: $md) {
        padding-top: clamp(4.0rem, 11vh, 7.2rem); 
    }

    &__title {
        color: #fff;
        font-family: "CaustenEB" !important;
        font-size: 2.5rem;
        text-align: center;
    
        @media screen and (max-width: $md) {
            padding: 0 50px;
            padding-top: 3rem;
            font-size: 32px;
        }

        @media screen and (min-width: $md) {
            font-size: clamp(1rem, 5vh, 3.5rem);
        }
    }
    
    &__titleText {
        color: #fff;
        font-family: "CaustenEB" !important;
        @media screen and (max-width: $md) {
            line-height: 1;
        }
    }

    &__image {
        display: flex;
        flex-direction: column;
        max-height: 35vh;
        margin-top: 30px;
        @media screen and (max-width: $sm) {
            margin-top: 15px;
        }

        img, video {
            height: 300px;
            width: auto;
            object-fit: contain;
            border-radius: 5%;

            @media screen and (max-width: $md) {
                max-height: 200px;
                max-width: 300px;
            }

            @media screen and (min-width: $md) {
                height: 35vh;
            }
        }
    }

    &__info {
        margin-top: 32px;
        margin-bottom: 20px;
        min-height: 50px;
        color: #fff;
        font-family: "CaustenM" !important;
        font-size: 22px;
        text-align: center; 
        width: 80%;

        @media screen and (max-width: $md) {
            font-size: 15px;
            margin-top: 1.75rem;
            padding-bottom: 100px;
        
        }
        @media screen and (min-width: $md) {
            margin-top: clamp(0.5rem, 4vh, 2rem);
            margin-bottom: clamp(0.5rem, 4vh, 2rem);        
            font-size: clamp(1rem, 3vmin, 3rem);
        }

        @media screen and (max-width: $md) and (min-height: $md) {
            font-size: 30px;        
        }
    }

    &__infoText {
        color: #fff;
        font-family: "CaustenL" !important;
        white-space: pre-line;
    }

    &__connect {
        text-align: center;
    }
}
