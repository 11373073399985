.discardSlide{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    & > p{
        font-family: 'CaustenR';
        font-style: normal;
        font-weight: 400;
        font-size: 1.25rem;
        line-height: 1.5rem;
        text-align: center;
        margin-top: 1rem;
        margin-bottom: 3rem;
    }
}