@import "../../styles/abstracts/_variables.scss";
.horizonatalTile{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 6.25rem;
    cursor: pointer;
    color: black;
    &__image{
        width: 33%;
        height: 100%;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        display: flex;
        align-items: center;
        background-color: $qq-green;
        & > img {
            width: 100%;
            height: 100%;
            // object-fit: cover;
            // overflow: hidden;
            // margin: 0 0.5rem;
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
            object-fit: cover;
        } 
    }
    &__description{
        width: 70%;
        height: 100%;
        display: flex;
        flex-direction: column;
        background-color: #FFF;
        padding: 1rem 1.25rem 1rem 1.25rem;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        justify-content: space-between;

        &__titleContainer{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }

        &__title{
            font-family: 'CaustenR';
            font-style: normal;
            font-weight: 700;
            font-size: 1.25rem;
            line-height: 1.5rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        &__subtitle{
            font-family: 'CaustenR';
            font-style: normal;
            font-weight: 500;
            font-size: 0.9rem;
            // line-height: 28px;          
            color: #222222;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        &__status{
            font-family: 'CaustenR';
            font-style: normal;
            font-weight: 500;
            font-size: 0.9rem;
            // line-height: 28px;          
            color: $grey-3;
            white-space: nowrap;
            overflow: wrap;
            text-overflow: ellipsis;
        }
    }
}