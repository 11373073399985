@import "../../styles/abstracts/variables";
.premiumModal{
  width: 85%;
  @media screen and (max-width:$md) {
    width: 95%;
  }

  &__details{
    list-style-type: circle;
    margin-left: 2rem;
    @media screen and (max-width:$md) {
      margin-left: unset;
    }
    margin-bottom: unset;
  }

}