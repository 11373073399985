@import "../../styles/abstracts/variables";

.pricing{
    display: flex;
    background-color: $qq-green;
    height: auto;
    min-height: 100%;
    flex-direction: column;
    color: white;
    padding-top: 15vh;
    padding-left: 2vw;
    padding-right: 1vw;
    padding-bottom: 2rem;

    &__title{
        color: white;
        font-family: "CaustenEB";
        font-size: clamp(1.8rem, 5vw, 6.875rem);
        line-height: clamp(1.8rem, 5vw, 6.875rem);
        margin-bottom: 3rem;
    }

    &__right{
        display: flex;
        flex-direction: row;        
        @media screen and (max-width:$md) {
            flex-direction: column;
            margin: auto;
            width: 80%;
        }
        justify-items: center;
        justify-content: space-around;
        gap: 2rem;
    }

}

.pricingTile{
    display: flex;
    flex: 1 1 0;
    flex-direction: column;
    justify-content: space-between;
    background-color: $qq-yellow;
    padding-bottom: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    border-radius: 0.625rem;  
    &__content{
        padding-top: 1rem;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: flex-start;
        margin-bottom: 2rem;
        padding: 1rem 0.5rem;   
        border-radius: 0.625rem;
    }
    &__title{
        color: #FFF;
        font-family: "CaustenEB";
        font-size: clamp(1.875rem, 2.36vw, 2.75rem);
        letter-spacing: 0.0625rem;
        line-height: 2.125rem;
        text-align: center;
        margin-bottom: 1rem;
    }
    &__price{
        color: #FFF;
        font-family: "CaustenM";
        font-size: clamp(1rem, 2vw, 1.875rem);
        // line-height: 2.125rem;
        text-align: center;
        font-family: "CaustenM";
        margin-bottom: 1rem;
    }
    &__description{
        display: flex;
        align-items: flex-start;
        gap: 0.5rem;
        color: #FFF;
        font-family: "CaustenR";
        font-size: clamp(0.8rem, 2vw, 1.25rem);
        font-weight: 700;
        letter-spacing: 0.0206rem;
        line-height: 1.875rem;
        margin-bottom: 1rem;
    }
    &__button{
        background-color: transparent;
        color: #FFF;
        font-family: "CaustenR";
        font-size: 1.5rem;
        font-weight: 700;
        letter-spacing: 0.0444rem;
        line-height: 1.5rem;
        text-align: center;
    }
    &__icon{
        margin-top: 5px;
        @media screen and (max-width:$md) {
            margin-top: 8px;
        }
        
    }
}