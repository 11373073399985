@import "../../styles/abstracts/variables";

.AnsweredCountComponent {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;

    &__liveData {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $qq-yellow;
        color: white;
        padding: 0.5rem;
        font-family: "CaustenR";
        font-size: clamp(0.75rem, 3vh, 1.75rem);
        border: 1rem;
        border-radius: 0.5rem;
        width: 75%;
    }

    &__text {
        display: flex;
        justify-content: center;
        font-size: large;
        align-items: center;
        background-color: $qq-yellow;
        color: white;
        padding: 0.5rem 1rem 0.5rem 1rem;
        font-family: "CaustenR";
        font-size: clamp(0.75rem, 3vh, 1.75rem);
        border: 1rem;
        border-radius: 0.5rem;
    }
}