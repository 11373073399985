$qq-yellow: #FDB913; //yellow-1
$qq-dark-yellow: #ECA904; //yellow-2
$qq-yellow-3: #DB9B00; //yellow-3
$qq-blue: #30B2E7; //blue-1
$qq-dark-blue: #0097D4; //blue-2
$qq-blue-3: #007FB3; //blue-3
$qq-green: #75B543; //green-1
$qq-dark-green: #4B9710; //green-2
$qq-green-3:#388200;
$grey-3:#888888;
$qq-red: #FFCBD1;
$grey-1: #c1c1c1;

//Breakpoints
$xs: 320px;
$sm: 600px;
$md: 900px;
$lg: 1200px;
$xl: 1536px;

//padding code
// overflow-x: hidden;
// height: 100vh;
// width: 100%;
// padding-top: 20vh;
