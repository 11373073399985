@import "../../../styles/abstracts/_variables.scss";

.blueinput{
    font-family: "CaustenR";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    height: 40px;
    background-color: rgba($color: $qq-blue, $alpha: 0.2);
    border-radius: 0.3125rem;
    color: black;
    outline: none;
    border: none;
    padding-left: 1.5rem;
    overflow-wrap: break-word;
}

textarea::-webkit-scrollbar {
    width: 7px;
}

textarea::-webkit-scrollbar-track {
    background-color: $qq-dark-blue;
}

textarea::-webkit-scrollbar-thumb {
    background: $qq-blue-3; 
    border-radius: 7px;
}