@import "../../styles/abstracts/variables";

.redirect{    
    display: flex;
    flex-direction: column;
    height: 100vh; 
    width: 100%;
    background-color: $qq-blue;
    color: #FFF;
    justify-content: flex-start;
    align-items: center;
    padding-top: 10rem;

    & > p{
        padding: 1rem;
        font-size: xx-large;
    }
}