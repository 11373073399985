@import "../../styles/abstracts/_variables.scss";

.cnfEmail{  
    &__title{
        font-family: "CaustenR";
        font-size: 3.125rem;
        font-weight: 800;
        line-height: 3.125rem;
        text-align: center;
        @media screen and (max-width:$md) {
            font-size: 2rem;
            line-height: 2rem;            
        }            
    }
    &__subtitle{
        font-family: "CaustenR";
        font-size: clamp(0.9375rem, 3vw ,1.25rem);
        font-weight: 500;
        line-height: 1.25rem;
        text-align: center;
    }  
    &__subtitlesmall{
        font-family: "CaustenR";
        font-size: clamp(0.75rem, 3vw ,1rem);
        font-weight: 500;
        line-height: 1.25rem;
        text-align: center;
        margin-bottom: 1.5rem;
    }  
    &__input{ //customclass for Input Component div
        width: 75%;
        @media screen and (max-width:$sm) {
            width: 85%;         
        }
    } 
    &__icon{
        position: relative;
        top: -40%;
        left: 94%;
        cursor: pointer;
        @media screen and (max-width:$md) {
            left: 91%;           
        } 
    }
    &__resendcode{
        font-family: "CaustenR";
        font-size: clamp(0.9375rem, 3vw ,1.25rem);
        font-weight: 600;
        line-height: 1.25rem;
        position: relative;
        top: -10%;            
    }        
    &__savebtn{
        display: flex;
        justify-content: center;
        margin-top:3%;
        margin-bottom:1.625rem;
        & > button{
            width: 30vw;
            @media screen and (max-width:$md) {
                width: 50vw;
            }            
        }
    } 
}
.cnfEmail::-webkit-scrollbar {
    width: 7px;
}
.cnfEmail::-webkit-scrollbar-track {
    background-color: $qq-dark-green;
}
.cnfEmail::-webkit-scrollbar-thumb {
    background: $qq-green; 
    border-radius: 7px;
}
.moremargin{
    margin-bottom: 1.5rem;
}
.yellow{
    color: $qq-yellow;
    cursor: pointer;
}