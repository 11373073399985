@import "./../../../styles/abstracts/variables";

.quizBasic {
    display: flex;
    flex-direction: column;
    // justify-content: center;
    background-color: $qq-blue;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    padding-bottom: 1rem;
    width: 65%;
    margin: 0 auto;

    @media screen and (max-width: $md) {
        width: 80%;
        height: 100%;
        padding-top: 12rem;
        padding-bottom: 3rem;
        justify-content: space-between;
    }

    @media only screen and (min-width: $md) {
        padding-top: clamp(4rem, 11vh, 6.2rem); //clamp(2.5rem, 2vh, 5rem);
    }

    &__questioncount {
        padding-top: 4rem;
        color: #fff;
        font-family: "CaustenR";
        font-size: 20px;

        @media screen and (max-width: $md) {
            padding-top: 1rem;
            font-size: 12px;
            font-weight: 700;
            letter-spacing: 0.43px;

        }
    }

    &__answeredcount {
        color: #fff;
        font-family: "CaustenR";
        font-size: 16px;

        @media screen and (max-width: $md) {
            font-size: 12px;
            letter-spacing: 0.43px;
            line-height: 16px;
            padding: 0px;
        }
    }

    &__question {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #fff;
        font-family: "CaustenM";
        font-size: 22px;
        width: 100%;
        @media screen and (max-width: $md) {
            flex: unset;
            padding-bottom: 0.5rem;
            text-align: center;
        }
        @media screen and (min-width: $md) {
            padding-top: 2vh;
            padding-bottom: 1vh;
        }
       

        p:nth-child(1) {
            flex: 11;
            overflow-y: auto;
            font-size: 28px;

            @media only screen and (min-width: $md) {
                font-size: clamp(1rem, 3vmin, 3rem);
            }

            @media only screen and (max-width: $md) {
                max-height: none;
                font-size: 15px;
                // padding-top: 3vh;
                line-height: 15px;
            }

            @media screen and (max-width: $md) and (min-height: $md) {
                max-height: unset;
                font-size: 30px;
                line-height: 30px;
            }
           
        }

        p:nth-child(2) {
            font-size: 1rem;
            flex: 1;
            text-align: center;

            @media screen and (max-width: $md) {
                display: none;
            }
        }
    }

    &__container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        @media screen and (min-width: $md) {
            width: 100vw;
        }
        @media screen and (max-width: $md) {
            padding-top: 1rem;
        }

        &__image {
            border-radius: 5px;
            // max-height: 312px;
            width: 100%;
            display: flex;
            justify-content: center;
            min-height: 30vh;
            
            @media screen and (min-width: $md) {
                max-height: 35vh;
                margin-top: clamp(1rem, 2vh, 2.5rem);
                margin-bottom: clamp(1rem, 2vh, 2.5rem);
                max-width: 80%;
                height: 40%;
            }
            @media screen and (max-width: $md) {
                // flex: unset;
                // margin: unset;
                // max-height: unset;
                height: 40%;
                width: 100%;
                
            }
            flex-grow: 1;
            @media screen and (min-height: 900px) {
                margin-top: 2rem;
                margin-bottom: 2rem;
            }
    
     
            img {
                // height: 312px;
                max-width: 100%;
                border-radius: 5%;
                object-fit: contain;
    
                @media screen and (min-width: $md) {
                    // height: 35vh;
                }
    
                @media screen and (max-width: $md) {
                    height: unset;
                    // width: 100%;
                }
    
            }
        }

        &__skipBtn {
            display: flex;
            align-items: center;
        }

        &__answerCount {
            display: flex;
            align-items: center;
        }

        @media screen and (max-width: $md) {
            flex-direction: column; 
            justify-content: center;
            align-items: center;
        
            &__answerCount {
              order: 1;
            }
        
            &__skipBtn {
              order: 2;
              padding-top: 1rem;
            }
        
            &__image {
              order: 3;
            }
        }
    }

    &__choices {
        // padding-bottom: 4rem;
        width: 100%;
        font-size: 28px;

        @media only screen and (max-width: $md) {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
    }

    &__fib {
        // padding-bottom: 4rem;
        width: 100%;
        font-size: 28px;
        padding-top: 3rem;
        @media only screen and (max-width: $md) {
            padding-top: 2rem;
        }
    }

    &__mobileLeaderboard {
        padding-top: 8rem;
    }
}

.quizBasic__leaderboardOpen {
    width: 85%;
}
