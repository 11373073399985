@import "../../styles/abstracts/variables";
.MuiListItemText-root{
    font-family: 'CaustenR';
    font-style: normal;
    font-weight: 700;
    font-size: 1.5625rem;
    text-transform: uppercase;
    color: #FFFFFF;
    text-align: left;
    margin-left: 3.125rem;
    @media screen and (max-width:1200px) {
        margin-left: 0.75rem;
    }
}

.Mui-selected{
    background-color: $qq-yellow-3 !important;
}