@import "../../styles/abstracts/_variables.scss";

.shareqtest {
  height: 100vh;
  background-color: #75b543;
  color: white;
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: $sm) {
    min-height: 100%;
    height: auto;
  }
  @media only screen and (max-height: 756px) { //if you change max-height also change it for the BOX in js file
    min-height: 100%;
    height: auto;
  }

  &__hostActions {
    padding: 0 5%;
  }
  
  &__linkAndShare {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-top: 1rem;
    @media only screen and (max-width: $sm) {
      height: 2.5rem;
      align-items: normal;
    }
  }
  &__link {
    display: flex;
    gap: 2.5rem;
    justify-content: center;
    background-color: #4b9710;
    align-items: center;
    padding: 0.8rem 1rem;
    border-radius: 10px;
    flex-grow: 0.6;
    gap: 0;

    @media only screen and (max-width: $md) {
      flex-grow: 0.8;
    }

    @media only screen and (max-width: $sm) {
      font-size: 0.8rem;
      flex-grow: 1;
      font-family: "CaustenM";
    }

    & > p:first-child {
      color: white;
      font-family: "CaustenM";
      font-size: 1.75rem;
      letter-spacing: 0.0063rem;
      line-height: 2.125rem;
      width: 6rem;
      overflow-x: auto;
      flex-grow: 1;
      overflow: hidden; 
      white-space: nowrap;
      &::-webkit-scrollbar {
        height: 0px;
      }
      @media only screen and (max-width: $sm) {
        width: 6rem;
        overflow-x: auto;
        flex-grow: 1;
        font-size: 0.9375rem;
        line-height: 0.9375rem;
        &::-webkit-scrollbar {
          height: 0px;
        }
      }
    }

  }
  &__copyButton {
    color: $qq-yellow;
    margin-left: "auto";
    font-family: "CaustenSB";
    font-size: 1.4375rem;
    letter-spacing: 0.0425rem;
    line-height: 1.4375rem;
    @media only screen and (max-width: $sm) {
      font-size: 0.875rem;
      line-height: 1rem;
    }
  }
  
  &__buttonContainer {
    display: flex;
    justify-content: center;
    gap: 3rem;
    margin-top: auto;
    padding-bottom: 4rem;
    @media only screen and (max-width: $sm) {
      margin-top: 1rem;
      flex-direction: column;
      align-items: center;
      gap: 1rem;
    }
  }
  &__button {
    border: 1px solid #fff;
    height: 3rem;
    width: 15rem;
    color: white;
    &:hover {
      background-color: $qq-yellow;
      color: white;
      border-color: $qq-yellow;
    }
  }
  &__noPlayers {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;

    & > img {
      height: 7rem;
      object-fit: contain;
      @media only screen and (max-width: $sm) {
        height: 5rem;
      }
    }
    & > p {
      font-family: "CaustenM";
    }
  }

  &__infoText {
    @media only screen and (min-width: $md) {
      font-size: 1.20rem;
    }
  }

  &__modal {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    width: 100%;

    & > h1 {
      font-size: 4.375rem;
      font-family: "CaustenEB";
      letter-spacing: 0.015rem;
      line-height: 4.375rem;
      text-align: center;

      @media only screen and (max-width: $sm) {
        font-size: 2rem;
        line-height: 2rem;
      }
      @media only screen and (max-width: $xl) {
        font-size: 2.5rem;
        line-height: 2.5rem;
      }
    }
  }

  &__modalButtonContainer {
    display: flex;
    align-items: center;
    gap: 2rem;
    @media only screen and (max-width: $sm) {
      flex-direction: column;
      gap: 1rem;
    }
  }

  &__modalButton {
    height: 4.6875rem;
    width: 14.75rem;
    background-color: #30b2e7;
    box-shadow: none !important;
    border-radius: 10px !important;
    font-family: "CaustenR" !important;
    font-size: 1.75rem !important;
    font-weight: 700 !important; 
    letter-spacing: 0.0512rem !important;
    line-height: 1.75rem !important;

    @media only screen and (max-width: $sm) {
      height: 3rem;
      width: 7.375rem;
      font-size: 1rem !important;
    }
  }
}
