@import "./../../styles/abstracts/variables";

.quizPreviewComponent {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow: hidden;
    // min-height: 88.3vh;
    // height: 100%;
    height: 100vh; // Added by sani

    &__questionArrows{
        display: flex;
        align-items: center;
        background-color: $qq-blue;
        height: 100vh;
    }

    &__leftArrow{
        padding-left: 2rem;
        cursor: pointer;
    }

    &__rightArrow{
        padding-right: 2rem;
        cursor: pointer;
    }

    &__content {
        // flex: 0.7;
        height: 100%;
        flex-grow: 1;
        overflow-y: auto;
        // display: grid;
        justify-items: center;
        align-items: start;
        background-color: $qq-blue;
        position: relative;
        transition: width 0.2s ease-out;
        @media screen and (max-width: $sm) {
            // min-height: 100vh;
        }
        @media screen and (min-width: $md) {
            // margin-bottom: 90px;
            // padding-bottom: 90px;
        }
        @media screen and (min-width: $md) and (min-height: $md) {
            // margin-bottom: 90px;
            padding-bottom: 120px;
        }
        @media only screen and (min-width: $md) {
            //for firefox
            scrollbar-color: #007fb3 #30b2e7;
        }
    }

    &__content::-webkit-scrollbar {
        @media only screen and (min-width: $md) {
            // display: none;
            width: 7px;
        }
    }

    &__content::-webkit-scrollbar-track {
        @media only screen and (min-width: $md) {
            background-color: rgba($color: #000000, $alpha: 0.0);
        }
    }

    /* Handle */
    &__content::-webkit-scrollbar-thumb {
        @media only screen and (min-width: $md) {
            background: #007fb3;
            border-radius: 7px;
        }
    }

    &__nextBtnFooter {
        display: flex;
        justify-content: center;
        align-items: center;
        @media screen and (max-width: 899px) {
            position: fixed;
            bottom: 0;
            height: 100px;
            width: 100%;
            background-color: #007fb3;
        }
    }
}