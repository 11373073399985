@import "./../../styles/abstracts/variables";

.quizSelectionComponent {
    background: linear-gradient(
        to right, 
        #0097d4 0%, 
        #0097d4 50%, 
        $qq-blue 50%, 
        $qq-blue 100%
    );
    @media only screen and (max-width: $md) {
        background: $qq-blue;
    }
    min-height: 100vh;
    min-height: 100dvh;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    color: white;
    height: 100vh;
    height: 100dvh;

    &__quizChoice {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        @media only screen and (max-width: $md) {
            flex-direction: column;
            padding-bottom: 4rem;
        }
    }

    &__readyMade,
    &__interestBased {
        flex: 1;
        height: 100%;
        @media only screen and (max-width: $md) {
            width: 100%;
            align-items: center;
        }
        display: flex;
        justify-content: center;
        background-color: #0097d4; 
    }

    &__interestBased {
        background-color: #30b2e7;
        @media only screen and (max-width: $md) {
            background-color: #007fb3;
        }
    }

    &__selected {
        flex: 11;
        align-items: unset;
        @media only screen and (max-width: $md) {
            flex: unset;
            height: 100%;
            background-color: $qq-blue;
        }
    }

    &__unselected {
        flex: 1;
        padding-top: unset;
        @media only screen and (max-width: $md) {
            flex: unset;
            height: 0%;
        }
    }

    /* For Safari 11+ */
    @media not all and (min-resolution:.001dpcm)
    { @supports (-webkit-appearance:none) and (stroke-color:transparent) {
        &__selected {
            width: 90%;
            align-items: unset;
            @media only screen and (max-width: $md) {
                flex: unset;
                width: unset;
                height: 100%;
            }
        }
    
        &__unselected {
            width: 10%;
            padding-top: unset;
            @media only screen and (max-width: $md) {
                width: unset;
                flex: unset;
                height: 0%;
            }
        }
    }}

    &__wrapper {
        padding: 3rem;
        padding-top: 18vh;
        @media only screen and (max-width: $md) {
            padding: unset;
        }
    }
    &__buttonWrapper {
        margin: clamp(1rem, 2vmin, 2rem);
        display: flex;
        justify-content: center;

        @media only screen and (max-width: $md) {
            margin: unset;
        }
    }
    &__description {
        display: flex;
        justify-content: center;
        margin-top: clamp(1rem, 1vmin, 2rem);
        & > p {
            text-align: center;
            font-family: "CaustenM";
            width: 70%;
            font-size: clamp(1rem, 2vmin, 3rem);
            line-height: clamp(1.2rem, 1vmin, 2.4rem);
            color: #fff;
        }
    }

    &__sidewaysText {
        cursor: pointer;
        writing-mode: vertical-rl;
        transform: scale(-1);
        font-family: "CaustenR";
        font-size: 34px;
        line-height: 34px;
        color: #fff;
        padding: 1rem;
        text-transform: uppercase;
        max-width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        @media only screen and (max-width: $md) {
            display: none;
        }
    }

    &__container {
        display: flex;
        flex-direction: column;
        width: 90%;
        align-items: center;
    }

    &__quizTitle {
        font-family: "CaustenEB";
        font-size: 70px;
        line-height: 70px;
        color: #fff;
        text-align: center;
        @media only screen and (max-width: $md) {
            font-size: 30px;
            padding-top: 7rem;
            line-height: unset;
        }
    }
    &__quizList {
        overflow-y: auto;
        margin-bottom: 2rem;
        @media only screen and (max-width: $md) {
            margin-bottom: 1rem;
        }
        display: flex;
        justify-content: center;
        width: 100%;
        //for firefox
        scrollbar-color: #007fb3 #30b2e7;
        @media only screen and (min-width: $md) {
            //for firefox
            scrollbar-color: #007fb3 #30b2e7;
        }

    }
    .interestBasedQuiz {
        margin-bottom: 6rem;
    }
    &__quizList::-webkit-scrollbar {
        width: 7px;
        @media only screen and (min-width: $md) {
            width: 7px;
        }
    }

    &__quizList::-webkit-scrollbar-track {
        background-color: rgba($color: #000000, $alpha: 0.0);
        @media only screen and (min-width: $md) {
            background-color: rgba($color: #000000, $alpha: 0.0);
        }
    }

    /* Handle */
    &__quizList::-webkit-scrollbar-thumb {
        background: #007fb3;
        border-radius: 7px;
        @media only screen and (min-width: $md) {
            background: #007fb3;
            border-radius: 7px;
        }
    }

    &__proceedButton {
        position: absolute;
        bottom: 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        padding: 1rem;
    }
}
